<template>
	<div>
		<div class="form_loading" v-if="isLoading"></div>
		<div class="my-accaunt white-layout">
			<header>
				<div class="container">
					<div class="wrapper_box">
						<div class="left_box">
							<div class="logo">
								<a :href="$store.state.domain">
									<img
										src="../assets/img/colorcinch_logo-1.png"
										class="pc"
										alt
									/>
									<img
										src="../assets/img/colorcinch-dark-300x118.png"
										class="mob"
										alt
									/>
								</a>
							</div>
						</div>
						<div class="right_box">
							<div class="header_burder_mob" @click="toggleMenu">
								<div class="line first"></div>
								<div class="line second"></div>
								<div class="line third"></div>
							</div>

							<nav class="menu">
								<ul>
									<li>
										<a :href="$store.state.domain + '/features/'">Features</a>
									</li>
									<li>
										<a :href="$store.state.domain + '/learn/'">Learn</a>
									</li>
									<li>
										<a :href="$store.state.domain + '/support/'">Support</a>
									</li>
									<li v-if="USER">
										<a href="/logout3">
											<i class="scape-ui-log-in"></i>
											Sign out</a
										>
									</li>
								</ul>
							</nav>
							<div class="wrap_btn">
								<a href="#" class="sign_in" v-if="!USER">
									<span class="icon">
										<svg
											version="1.1"
											id="Capa_1"
											xmlns="http://www.w3.org/2000/svg"
											xmlns:xlink="http://www.w3.org/1999/xlink"
											x="0px"
											y="0px"
											width="438.542px"
											height="438.542px"
											viewBox="0 0 438.542 438.542"
											style="enable-background: new 0 0 438.542 438.542"
											xml:space="preserve"
										>
											<g>
												<g>
													<path
														d="M414.41,60.676c-16.084-16.083-35.446-24.125-58.102-24.125h-91.357c-2.478,0-4.429,0.614-5.853,1.855
			c-1.431,1.237-2.382,3.093-2.854,5.568c-0.479,2.474-0.76,4.709-0.853,6.707c-0.096,1.997-0.051,4.521,0.144,7.565
			c0.186,3.046,0.281,4.949,0.281,5.713c0.571,1.524,0.767,2.81,0.571,3.855c-0.191,1.043,0.476,1.903,1.998,2.568
			c1.52,0.666,2.279,1.191,2.279,1.569c0,0.378,1.096,0.662,3.285,0.855c2.19,0.192,3.289,0.284,3.289,0.284h3.713h3.142h82.228
			c12.56,0,23.312,4.471,32.258,13.422c8.952,8.945,13.422,19.696,13.422,32.261V319.77c0,12.563-4.47,23.312-13.422,32.258
			c-8.945,8.948-19.698,13.421-32.258,13.421h-91.357c-2.478,0-4.436,0.62-5.853,1.855c-1.43,1.242-2.382,3.094-2.857,5.564
			c-0.476,2.478-0.763,4.716-0.855,6.714c-0.092,1.995-0.051,4.518,0.144,7.563c0.195,3.046,0.288,4.948,0.288,5.708
			c0,2.478,0.896,4.613,2.707,6.427c1.81,1.807,3.949,2.71,6.427,2.71h91.357c22.648,0,42.018-8.042,58.095-24.133
			c16.084-16.077,24.126-35.446,24.126-58.095V118.769C438.533,96.118,430.491,76.754,414.41,60.676z"
													/>
													<path
														d="M338.047,219.27c0-4.948-1.813-9.233-5.427-12.85L177.302,51.101c-3.616-3.612-7.895-5.424-12.847-5.424
			c-4.952,0-9.233,1.812-12.85,5.424c-3.615,3.617-5.424,7.904-5.424,12.85v82.226H18.274c-4.952,0-9.235,1.812-12.851,5.424
			C1.807,155.219,0,159.503,0,164.452v109.635c0,4.949,1.807,9.233,5.424,12.848c3.619,3.61,7.902,5.427,12.851,5.427h127.906
			v82.225c0,4.945,1.809,9.233,5.424,12.847c3.621,3.617,7.902,5.428,12.85,5.428c4.949,0,9.231-1.811,12.847-5.428l155.318-155.312
			C336.234,228.501,338.047,224.216,338.047,219.27z"
													/>
												</g>
											</g>
											<g />
											<g />
											<g />
											<g />
											<g />
											<g />
											<g />
											<g />
											<g />
											<g />
											<g />
											<g />
											<g />
											<g />
											<g />
										</svg>
									</span>
									Sign in
								</a>
								<a
									:href="$store.state.routerPrefixURL + '/'"
									class="btn get_started"
									:style="{ color: '#fff' }"
									>Get started</a
								>
							</div>
						</div>
					</div>
				</div>
			</header>
			<main>
				<div class="container">
					<div class="wrapper_main">
						<transition-group name="fade"  mode="out-in">
							<div class="message success" key="1" v-if="successMessage">
								<p class="message-content success">
									{{
									successTextMessage
									? successTextMessage
									: "Your changes have been saved."
									}}
								</p>
								<a
										href="#"
										class="close"
										@click.prevent="closeMessage('successMessage')"
								>
								</a>
							</div>
							<div class="message warning" key="2" v-if="warningMessage">
								<p class="message-content warning">
									Please verify your email address.
									<a class="resend-email" @click="resendVerificationEmail">
										Resend email
									</a>
								</p>
								<a
										href="#"
										class="close"
										@click.prevent="closeMessage('warningMessage')"
								>

								</a>
							</div>
							<div class="message warning" key="3" v-if="expireWarningMessage">
								<p class="message-content warning">
									Your card will expire soon. Please update your payment information.
								</p>
								<a
										href="#"
										class="close"
										@click.prevent="closeMessage('expireWarningMessage')"
								>

								</a>
							</div>
							<div class="message error" key="4" v-if="errorMessage">
								<p class="message-content error">
									{{
									errorTextMessage
									? errorTextMessage
									: "Your plan has been paused. We were unable to charge you for your subscription. Please update your payment information to resume it."
									}}
								</p>
								<a
										href="#"
										class="close"
										@click.prevent="closeMessage('errorMessage')"
								>
								</a>
							</div>
						</transition-group>

						<h1 class="main_title">My Account</h1>

						<form action class="form">
							<!-- <div class="item">
								<div class="head">
									<div class="title">Referrals</div>
								</div>
								<div class="body">
									<div class="line">
										<div class="left_box">
											<label for>Referral URL</label>
											<input
												type="text"
												ref="referralURL"
												:value="USER.referal_url"
												readonly
											/>
										</div>
										<div class="right_box">
											<a
												href="#"
												class="icon edit"
												v-on:click.prevent="copyReferralURL()"
											>
												Copy
											</a>
										</div>
									</div>

									<div class="line">
										<div class="left_box">
											<label for>Commission</label>
											<input
												type="text"
												:value="(USER.referal_amount || 0) + ' USD'"
												readonly
											/>
										</div>
									</div>
									
									<div class="line">
										<div class="left_box">
											<label for>Visitors</label>
											<input
												type="text"
												:value="USER.getrewardful_visitors || 0"
												readonly
											/>
										</div>
									</div>

									<div class="line">
										<div class="left_box">
											<label for>Leads</label>
											<input
												type="text"
												:value="USER.getrewardful_leads || 0"
												readonly
											/>
										</div>
									</div>

									<div class="line">
										<div class="left_box">
											<label for>Conversions</label>
											<input
												type="text"
												:value="USER.getrewardful_conversions || 0"
												readonly
											/>
										</div>
									</div>
								</div>
							</div> -->

							<div class="item">
								<div class="head">
									<div class="title">Sign In & Security</div>
								</div>
								<div class="body">
									<div class="line">
										<div class="left_box">
											<label for>Email</label>
											<input v-if="USER" type="email" readonly :value="USER.email" />
										</div>
										<div class="right_box">
											<a
												href="#"
												class="icon edit"
												v-on:click.prevent="changePopup('changeEmail', true)"
											>
												<img src="../assets/img/edit_input.svg" alt />
											</a>
										</div>
									</div>
									<div class="line">
										<div class="left_box">
											<label for>Password</label>
											<input type="text" value="• • • • • • • •" readonly />
										</div>
										<div class="right_box">
											<a
												href="#"
												class="icon edit"
												v-on:click.prevent="changePopup('changePassword', true)"
											>
												<img src="../assets/img/edit_input.svg" alt />
											</a>
										</div>
									</div>
								</div>
							</div>


							<FreePlanPopup  v-if="USER && USER.status == 'free'"/>


							<div class="item" v-if="USER && USER.status != 'free'">
								<div class="head">
									<div class="title">My Subscription</div>
								</div>
								<div class="body">
									<div class="line">
										<div class="left_box">
											<label for>Name</label>
											<div v-if="!USER.pay_in_google_play" class="text">{{ USER ? USER.name : "" }}</div>
											<div v-if="USER.pay_in_google_play" class="text">
												<a v-if="USER.pay_in_google_play" href="https://play.google.com/store/account/subscriptions" class="icon edit">
													View
												</a>
											</div>
										</div>
									</div>
									<div class="line" v-if="hideSuperAdmin() && !USER.pay_in_google_play">
										<div class="left_box">
											<label for>Card</label>
											<div class="text">
												<div v-if="card_info || getLast4Card">
													<span>• • • •</span>
													<span>• • • •</span>
													<span>• • • •</span>
													{{ getLast4Card }}
												</div>
											</div>
										</div>
										<div class="right_box">
											<a
												href="#"
												class="icon edit"
												v-on:click.prevent="changePopup('updateCard', true)"
												>Update</a
											>
										</div>
									</div>

									<div class="line" v-if="hideSuperAdmin() && isHaveCard()">
										<div class="left_box">
											<label for>Plan</label>
											<div class="text" v-if="getUserPlan">
												<div class="title">
													Colorcinch Plus - {{ getUserPlan.title }}
												</div>
												<div class="content" v-if="USER.isPremium && !USER.subscribeDetails.canceled && !USER.subscribeDetails.cancel_at_period_end && USER.subscribeDetails.payment_status == 'active' ">
													Your plan will renew on
													{{ getUserPlan.next_payment_string }}, for a total of
													<br />
													${{ getChangedPrice() }}.
												</div>
												<div class="content" v-else-if="(USER.subscribeDetails.canceled && USER.subscribeDetails.next_payment_string && USER.is_user_cancel) || USER.subscribeDetails.cancel_at_period_end" style="padding-right: 20px; line-height: 1.6; padding-top: 10px;">
													Your plan has been cancelled at your request. Your subscription will end on {{USER.subscribeDetails.next_payment_string}}.
                                                    <a style="color:#fa6400"
                                                            href="#"
                                                            v-on:click.prevent="changePopup('changeSubscriptionPlan', true)"
                                                    >Resubscribe
                                                    </a>
                                                </div>

												<div class="content" v-else-if="USER.subscribeDetails.payment_status == 'past_due'" style="padding-right: 20px; line-height: 15px; padding-top: 10px;">
													Your plan has been paused. We were unable to charge you for your subscription.
												</div>

												<div class="content" v-else-if="USER.subscribeDetails.canceled && !USER.is_user_cancel" style="padding-right: 20px; line-height: 15px; padding-top: 10px;">
													Your plan has been canceled. Please update your payment information to resubscribe.
												</div>

												<div class="content" v-else-if="USER.subscribeDetails.canceled && !USER.subscribeDetails.next_payment_string" style="padding-right: 20px; line-height: 15px; padding-top: 10px;">
													You subscription has expired.<br>
													Yo no longer have access to Colorcinch Plus benefits.
												</div>
											</div>
										</div>
										<div class="right_box">
											<a v-if="!USER.pay_in_google_play" href="#" class="icon edit" v-on:click.prevent="changePopup('changeSubscriptionPlan', true)">
												MANAGE
											</a>
											<a v-if="USER.pay_in_google_play" href="https://play.google.com/store/account/subscriptions" class="icon edit">
												View
											</a>
										</div>
									</div>

									<!-- <div class="promo_code_info" style="opacity: 1; z-index: 999">
										<div class="left">
											<span class="_code">FREEMONTH</span> |
											<span class="_text"
												>First Month Free - Saving $5.99 (100%)</span
											>
										</div>
									</div> -->
								</div>
							</div>
							<div class="item">
								<div class="head">
									<div class="title">Close My Account</div>
								</div>
								<div class="body">
									<div class="content">
										By deactivating your account, your Colorcinch Plus subscription
										(if active) will be cancelled <b class="bold-color">effective immediately</b> and
										you’ll no longer be able to access any of your designs or log in to Colorcinch.
									</div>
									<a @click="deactivateMyAccount" class="btn btn_border"
										>Deactivate account</a
									>
								</div>
							</div>
						</form>
					</div>
				</div>
			</main>
			<footer class="myacc">
				<div class="container">
					<div class="wrapper_col">
						<div class="col-5">
							<div class="logo">
								<a :href="$store.state.domain + `/`">
									<img src="../assets/img/colorcinch-dark-300x118.png" alt />
								</a>
							</div>

							<div class="rights_reserved">
								All Rights Reserved
								<br />© Colorcinch
							</div>
						</div>
						<div class="col-5">
							<div class="title">Product</div>

							<ul class="menu">
								<li>
									<a :href="$store.state.domain + `/`">Home</a>
								</li>
								<li>
									<a :href="$store.state.routerPrefixURL + '/'">Get started</a>
								</li>
								<li>
									<a :href="$store.state.domain + `/features`">Features</a>
								</li>
								<li>
									<a :href="$store.state.domain + `/pricing`">Pricing</a>
								</li>
							</ul>
						</div>
						<div class="col-5">
							<div class="title">Company</div>

							<ul class="menu">
								<li>
									<a :href="$store.state.domain + `/about`">About us</a>
								</li>
								<li>
									<a :href="$store.state.domain + `/about/#leaders`"
										>Leadership</a
									>
								</li>
								<li>
									<a :href="$store.state.domain + `/about/#positions`"
										>Careers</a
									>
								</li>
								<li>
									<a :href="$store.state.domain + `/affiliate/`"
										>Affiliate program</a
									>
								</li>
								<li>
									<a :href="$store.state.domain + `/contact/`">Contact us</a>
								</li>
							</ul>
						</div>
						<div class="col-5">
							<div class="title">Information</div>

							<ul id="menu-footer-3" class="menu">
								<li
									class="menu-item menu-item-depth-0 menu-item-9425"
									data-id="menu-item-9425"
								>
									<a
										:href="$store.state.domain + `/category/getting-started`"
										class="menu-link main-menu-link item-title"
										>Getting started</a
									>
								</li>
								<li
									class="menu-item menu-item-depth-0 menu-item-9426"
									data-id="menu-item-9426"
								>
									<a
										:href="$store.state.domain + `/onlinecartoonizer`"
										class="menu-link main-menu-link item-title"
										>Online Cartoonizer</a
									>
								</li>
								<li
									class="menu-item menu-item-depth-0 menu-item-9427"
									data-id="menu-item-9427"
								>
									<a
										:href="$store.state.domain + `/fxcartoonizer`"
										class="menu-link main-menu-link item-title"
										>FX Cartoonizer</a
									>
								</li>
								<li
									class="menu-item menu-item-depth-0 menu-item-9428"
									data-id="menu-item-9428"
								>
									<a
										:href="$store.state.domain + `/support`"
										class="menu-link main-menu-link item-title"
										>FAQ</a
									>
								</li>
								<li
									class="menu-item menu-item-depth-0 menu-item-9429"
									data-id="menu-item-9429"
								>
									<a
										:href="$store.state.domain + `/category/blog`"
										class="menu-link main-menu-link item-title"
										>Blog</a
									>
								</li>
								<li
									class="menu-item menu-item-depth-0 menu-item-9430"
									data-id="menu-item-9430"
								>
									<a
										:href="$store.state.domain + `/support`"
										class="menu-link main-menu-link item-title"
										>Support</a
									>
								</li>
							</ul>
						</div>
						<div class="col-5">
							<div class="title">Subscribe to our newsletter:</div>

							<form action class="form_subscribe">
								<input type="email" placeholder="Enter your email" />
								<button></button>
							</form>
						</div>
					</div>
					<div class="bottom">
						<ul class="menu_b">
							<li>
								<a :href="$store.state.domain + `/terms/`">Terms of Service</a>
							</li>
							<li>
								<a :href="$store.state.domain + `/privacy-policy/`"
									>Privacy Policy</a
								>
							</li>
							<li>
								<a :href="$store.state.domain + `/attributions/`"
									>Disclaimers</a
								>
							</li>
						</ul>
					</div>

					<div class="mob_box">
						<div class="title">
							Product of
							<span>eVeek, LLC</span>
						</div>
						<div class="wrap">
							<div class="rights">© Colorcinch. All Rights Reserved.</div>
							<ul class="menu_mob">
								<li>
									<a href="#">Terms of Service</a>
								</li>
								<li>
									<a href="#">Privacy Policy</a>
								</li>
								<li>
									<a href="#">Disclaimers</a>
								</li>
							</ul>
						</div>
						<div class="logo">
							<a href="#">
								<img src="../assets/img/colorcinch-dark-300x118.png" alt />
							</a>
						</div>
					</div>
				</div>
			</footer>

			<div class="privacy_preferences_box" v-if="privacyBox">
				<div class="left_box">
					<p>
						We use cookies to ensure you get the best experience on our site. By
						continuing to use our site, you agree to our cookie policy.
					</p>
				</div>
				<div class="right_box">
					<a
						:href="$store.state.domain + '/privacy-policy'"
						target="_blank"
						class="privacy"
						>Privacy Preferences</a
					>
					<a @click="agreePrivacyPreferences" class="btn">I Agree</a>
				</div>

				<a href="#" class="close" @click="closePrivacy">
<!--					<img src="../assets/img/close_icon_window.svg" alt />-->
				</a>
			</div>
		</div>
		<div class="mob_menu_box_none" :class="{ mob_menu_box: menuStatus }">
			<div class="overlay"></div>
			<div class="wrapper_menu">
				<div class="close" @click="toggleMenu">
					<img src="../assets/img/close_menu_header.svg" alt />
				</div>
				<a href="#" class="sign_in">
					<span class="icon">
						<img src="../assets/img/sign_in_white.svg" alt />
					</span>
					Sign in
				</a>

				<form action>
					<input type="text" placeholder="Search" />
					<button></button>
				</form>

				<ul class="menu">
					<li>
						<a href="#">Features</a>
					</li>
					<li>
						<a href="#">Learn</a>
					</li>
					<li>
						<a href="#">Pricing</a>
					</li>
					<li class="sub_menu_item">
						<a href="#">
							More
							<span
								class="icon"
								:class="{ toggle: mobSubMenu }"
								@click="subMenuToggle"
							></span>
						</a>
						<ul class="sub_menu" :class="{ open_sub_menu: mobSubMenu }">
							<li>
								<a href="#">About us</a>
							</li>
							<li>
								<a href="#">Affiliate program</a>
							</li>
							<li>
								<a href="#">Contact us</a>
							</li>
						</ul>
					</li>
				</ul>

				<div class="rights_reserved">© All rights reserved.</div>
			</div>
		</div>
		<transition name="fade">
			<div class="popup_wrpr overlay-bg active" v-if="changeEmail">
				<div class="popup save_popup upgrade-popup my-account">
					<a
						class="close_popup_btn"
						v-on:click="changePopup('changeEmail', false)"
					>
						<span class="inch-icon inch-close-icon"></span>
					</a>
					<h2 class="title sm">Change Email</h2>
					<div class="form-group">
						<div class="label">New Email</div>
						<input
							type="email"
							v-model="changeEmailData.email"
							class="form-control"
						/>
					</div>
					<div class="form-group">
						<div class="label">Confirm New Email</div>
						<input
							type="email"
							v-model="changeEmailData.confirmEmail"
							class="form-control"
						/>
					</div>
					<div class="form-group">
						<div class="label">Password</div>
						<input
							type="password"
							v-model="changeEmailData.password"
							class="form-control"
						/>
					</div>
					<p class="info">
						A password is required to update your email. This keeps your account
						safe.
					</p>
					<p class="error" style="color: red">{{ updateEmailError }}</p>
					<div class="btn-wrap">
						<a
							@click="updateEmail"
							:class="{ disabled: !changeEmailData.email }"
							class="btn-orange"
							>Update Email</a
						>
					</div>
				</div>
				<div
					class="pop_up_closer"
					v-on:click="changePopup('changeEmail', false)"
				></div>
			</div>
		</transition>

		<transition name="fade">
			<div
				class="popup_wrpr overlay-bg active deactivate_popup"
				v-if="deactivateMyAccountPopup"
			>
				<div
					class="popup save_popup upgrade-popup my-account transition"
					:style="leavePopUpStyle()"
				>
					<a
						class="close_popup_btn"
						v-on:click="changePopup('deactivateMyAccountPopup', false)"
					>
						<span class="inch-icon inch-close-icon"></span>
					</a>
					<div
						:style="{
							width: '100%',
							'text-align': 'center',
							margin: ' 20px 0',
						}"
					>
						<img
							src="@/assets/emoji-sad-unhappy-smile-56062.webp"
							:style="{ width: '120px', opacity: 0.1, margin: '0 auto' }"
						/>
					</div>
					<p class="d_title">Are you sure you want to close your Colorcinch account?</p>
					<p class="d_content">

						Your Colorcinch Plus plan will be cancelled effective immediately and you’ll no longer be able to access any of your designs or log in to your account.

						<!--Your Colorcinch Plus plan will be cancelled effective immediately and <b>your subscription will end on
						{{USER.subscribeDetails.next_payment_string}}, at which time you will no longer have access to your account.</b>
-->
						<span v-if="false">
							Your Colorcinch Plus subscription will be cancelled effective
							immediately, and
							<b>you’ll no longer be able to log in or access any of your	projects.</b>
						</span>
					</p>

					<div class="sh-hr" style="margin-top: 30px"></div>
					<div class="form-group padding-model-box">
						<div class="label">Please tell us why you are leaving</div>

						<div class="drpdw">
							<DropdownSelect
								textLabel=""
								placeholder="Select a reason..."
								:selectClass="reasoneLeave.selectDropdown"
								@resetSelectClass="reasoneLeave.selectDropdown = ''"
								:selectOptions="reasoneLeave.options"
								@toggleDropdown="openDropdown"
								@selectDropdown="selectDrop"
								:current="reasoneLeave.value"
							/>
						</div>

						<div class="alert-form" v-if="alert.wrongReasone">
							Please make a selection
						</div>
					</div>

					<div class="btn-wrap deactive_button_wrap padding-model-box">
						<a @click="runDeactivate()" class="btn-red"
							>Yes, Close My Account</a
						>
						<span class="under_button">This change can't be undone.</span>
					</div>
				</div>
				<div
					class="pop_up_closer"
					v-on:click="changePopup('changePassword', false)"
				></div>
			</div>
		</transition>

		<transition name="fade">
			<div
				class="popup_wrpr overlay-bg active deactivate_popup"
				v-if="accountDeleted"
			>
				<div
					class="popup save_popup upgrade-popup my-account"
					style="padding: 48px 25px 70px !important"
				>
					<a
						class="close_popup_btn"
						v-on:click="changePopup('accountDeleted', false)"
					>
						<span class="inch-icon inch-close-icon"></span>
					</a>
					<div
						:style="{
							width: '100%',
							'text-align': 'center',
							margin: ' 20px 0',
						}"
					>
						<img src="@/assets/2uHnZAc.png" />
					</div>
					<p class="d_title" :style="{ color: '#000 !important' }">
						We're sad to see you go!
					</p>
					<div
						class="d_content"
						style="
							line-height: 1.9;
							font-size: 18px;
							font-weight: 300;
							font-family: 'soleil', Arial, Helvetica, sans-serif;
						"
					>
						<p data-v-14320997 class="fot">
							Your Colorcinch Plus subscription has been cancelled and your
							account closed effective immediately.
						</p>
						<p data-v-14320997 class="fot">
							Feel free to continue to use our free product and when you’re
							ready to give Colorcinch Plus another try, we’d love to have you
							back!
						</p>
					</div>

					<div class="sh-hr" style="margin-top: -10px; margin-bottom: 60px"></div>

					<div class="btn-wrap deactive_button_wrap padding-model-box">
						<a
							href="/"
							class="btn-orange"
							:style="{ width: '100%', 'text-align': 'center' }"
							>Return to home</a
						>
					</div>
				</div>
				<div
					class="pop_up_closer"
					v-on:click="changePopup('changePassword', false)"
				></div>
			</div>
		</transition>

		<transition name="fade">
			<div class="popup_wrpr overlay-bg active" v-if="changePassword">
				<div class="popup save_popup upgrade-popup my-account">
					<a
						class="close_popup_btn"
						v-on:click="changePopup('changePassword', false)"
					>
						<span class="inch-icon inch-close-icon"></span>
					</a>
					<h2 class="title sm">Change Password</h2>
					<div class="form-group">
						<div class="label">Current Password</div>
						<input
							type="password"
							v-model="changePasswordData.current"
							class="form-control"
						/>
					</div>
					<div class="form-group">
						<div class="label">New Password</div>
						<input
							type="password"
							v-model="changePasswordData.newPassword"
							class="form-control"
						/>
					</div>
					<div class="form-group">
						<div class="label">Confirm New Password</div>
						<input
							type="password"
							v-model="changePasswordData.confirmNewPassword"
							class="form-control"
						/>
					</div>
					<p class="error" style="color: red">{{ updatePasswordError }}</p>

					<div class="btn-wrap">
						<a
							@click="onChangePassword()"
							:class="{ disabled: !changePasswordData.newPassword }"
							class="btn-orange"
							>Change Password</a
						>
					</div>
				</div>
				<div
					class="pop_up_closer"
					v-on:click="changePopup('changePassword', false)"
				></div>
			</div>
		</transition>

		<transition name="fade">
			<div class="popup_wrpr overlay-bg active" v-if="updateCard">
				<div class="popup save_popup upgrade-popup update-card my-account">
					<a
						class="close_popup_btn"
						v-on:click="changePopup('updateCard', false)"
					>
						<span class="inch-icon inch-close-icon"></span>
					</a>
					<h2 class="title sm">Update Card</h2>
					<div class="form-group">
						<div class="label update-card">Name on Card</div>
						<!--:value="getUserCard().name || ''"
						@input="inputCardName"-->
						<input
							type="text"
							v-model="updateCardData.name"
							class="form-control"
							:class="{'border-red': formErrorsUpdateCard.type === 'name'}"
							placeholder="E.g. John Doe"
						/>
					</div>
					<div class="form-group">
						<div class="label update-card">Card Number</div>
						<div id='cardNumber' ref='cardNumber' :class="{'border-red': formErrorsUpdateCard.type === 'cardNumber'}"
						></div>
					</div>
					<div class="form-group-flex">
						<div class="form-group col-6">
							<div class="label update-card">MM / YY</div>
							<div id='cardExpiry' ref='cardExpiry' :class="{'border-red': formErrorsUpdateCard.type === 'cardExpiry'}"
							></div>
						</div>
						<div class="form-group col-6">
							<div class="label update-card">CVC</div>
							<div id='cardCvc' ref='cardCvc' :class="{'border-red': formErrorsUpdateCard.type === 'cardCvc'}"></div>
						</div>
					</div>
					<div class="form_errors">
						<ul id="example-1">
<!--							<li v-for="item in formErrorsUpdateCard">{{ item }}</li>-->
							<li v-if="formErrorsUpdateCard.message" style="line-height: 1.2;">{{ formErrorsUpdateCard.message }}</li>
						</ul>
					</div>
					<div class="btn-wrap">
						<a v-if="!USER.subscribeDetails.cancel_at_period_end && !USER.subscribeDetails.canceled && USER.subscribeDetails.payment_status != 'past_due'" @click="sendUpdatedCard(false)" class="btn-orange update-card">Update Card</a>
                        <a v-else @click="sendUpdatedCard(true)" class="btn-orange" style="width: 100%; text-align: center;">Update & Resubscribe</a>
                    </div>
				</div>
				<div
					class="pop_up_closer"
					v-on:click="changePopup('updateCard', false)"
				></div>
			</div>
		</transition>
		<transition name="fade">
			<div class="popup_wrpr overlay-bg active" v-if="changeSubscriptionPlan">
				<div class="popup save_popup upgrade-popup my-account">
					<a
						class="close_popup_btn"
						v-on:click="changePopup('changeSubscriptionPlan', false)"
					>
						<span class="inch-icon inch-close-icon"></span>
					</a>
					<h2 v-if="(USER.subscribeDetails.canceled && USER.subscribeDetails.next_payment_string) || USER.subscribeDetails.cancel_at_period_end" class="title sm " style="padding: 20px 15px 20px 0;margin: 0 30px 0 30px;">Select Subscription Plan</h2>
					<h2 v-else class="title sm " style="padding: 20px 15px 20px 0;margin: 0 30px 0 30px;">Change Subscription Plan</h2>
					<div class="plan-type-wrap" style="padding: 30px 25px 0;">
						<label
							class="plan-type"
							v-for="(plan, index) in planSubscribes"
							:key="index"
						>
							<span
								class="promo_applied"
								v-if="
									promo_code.data &&
									isCurrentSelected() &&
									plan.stripe_id == promo_code.data.plan_id
								"
								>Code applied</span
							>
							<input v-if="USER.subscribeDetails.cancel_at_period_end || USER.subscribeDetails.canceled"
								   type="radio"
								   name="plan"
								   :value="plan.id"
								   @input="changeSelectedPlan"
							/>
							<input v-else
								type="radio"
								name="plan"
								:value="plan.id"
								@input="changeSelectedPlan"
								:checked="isCurrentPlan(plan)"

							/>
							<div class="checkmark">
								<strong v-if="!USER.subscribeDetails.cancel_at_period_end && !USER.subscribeDetails.canceled && USER.subscribeDetails.payment_status != 'past_due' "
									>{{ plan.title }} – {{ parsePlanPrice(plan)
									}}{{ plan.current ? " (Current)" : "" }}</strong
								>

								<strong v-else-if="USER.subscribeDetails.payment_status == 'past_due' "
								>{{ plan.title }} – {{ parsePlanPrice(plan)
									}}{{ plan.current ? " (Paused)" : "" }}</strong
								>
								<strong v-else-if="USER.subscribeDetails.cancel_at_period_end || USER.subscribeDetails.canceled"
								>{{ plan.title }} – {{ parsePlanPrice(plan)
									}}</strong
								>
								<span :class="{ decoration: parsePlanPrice(plan) == 'FREE' }">{{
								 renderPlanDescription(plan)
								}}</span>
							</div>
						</label>
					</div>
					<div class="promo_code_info" :style="getPromocodeStyle()">
						<div class="left">
							<span class="_code">{{ getPromoCode() }}</span> |
							<span class="_text">{{ getPromoSavingPrice() }}</span>
						</div>
					</div>
					<p class="info" style="padding: 0px 32px;">
						Both subscription plans include all Colorcinch Plus features, all
						graphic collections and unlimited projects.
					</p>
					<div class="error_subscription_popup" v-if="formErrorsSubscribeCard.message">
						{{ formErrorsSubscribeCard.message }}
					</div>
					<div class="btn-wrap d-flex" style="padding: 0 0 0 20px;">
						<a v-if="!USER.subscribeDetails.cancel_at_period_end && !USER.subscribeDetails.canceled"
							@click="changeSubPlan(false)"
							:class="{ disabled: !planSelectedId || isCurrentSelected() }"
							class="btn-orange change-sub-card"
							>Save Changes</a
						>

						<a v-if="USER.subscribeDetails.cancel_at_period_end || USER.subscribeDetails.canceled"
						   @click="changeSubPlan(false)"
						   class="btn-orange"
						>Resubscribe</a
						>

<!--                        <a v-else-->
<!--                           @click="changeSubPlan"-->
<!--                           :class="{ disabled: !planSelectedId || isCurrentSelected() }"-->
<!--                           class="btn-orange"-->
<!--                        >Resubscribe</a-->
<!--                        >-->

						<a
							href="#"
							class="btn-cancel-sub"
                            v-if="!USER.subscribeDetails.cancel_at_period_end && !USER.subscribeDetails.canceled"
							v-on:click.prevent="changePopup('cancelSubCard', true)"
							>Cancel Subscription</a>
					</div>
				</div>
				<div
					class="pop_up_closer"
					v-on:click="changePopup('changeSubscriptionPlan', false)"
				></div>
			</div>
		</transition>
		Your card number is invalid.
		<transition name="fade">
			<div
					class="popup_wrpr overlay-bg active deactivate_popup"
					v-if="cancelSubCard"
			>
				<div
						class="popup save_popup upgrade-popup my-account transition"
						:style="leavePopUpStyle()"
				>
					<a
							class="close_popup_btn"
							v-on:click="changePopup('cancelSubCard', false)"
					>
						<span class="inch-icon inch-close-icon"></span>
					</a>
					<div
							:style="{
							width: '100%',
							'text-align': 'center',
							margin: ' 20px 0',
						}"
					>
						<img
								src="@/assets/emoji-sad-unhappy-smile-56062.webp"
								:style="{ width: '120px', opacity: 0.1, margin: '0 auto' }"
						/>
					</div>
					<p class="d_title">Are you sure you want to cancel your subscription?</p>
					<p class="d_content">

						Your Colorcinch Plus plan will be cancelled effective immediately and your subscription will <b>end on
						{{USER.subscribeDetails.next_payment_string}},</b> at which time you will no longer have access to plus benefits.

						<span v-if="false">
							Your Colorcinch Plus subscription will be cancelled effective
							immediately, and
							<b>you’ll no longer be able to log in or access any of your	projects.</b>
						</span>
					</p>

					<div class="sh-hr" style="margin-top: 30px"></div>
					<div class="form-group padding-model-box" >
						<div class="label">Please tell us why you are leaving</div>

						<div class="drpdw">
							<DropdownSelect
									textLabel=""
									placeholder="Select a reason..."
									:selectClass="reasoneLeave.selectDropdown"
									@resetSelectClass="reasoneLeave.selectDropdown = ''"
									:selectOptions="reasoneLeave.options"
									@toggleDropdown="openDropdown"
									@selectDropdown="selectDrop"
									:current="reasoneLeave.value"
							/>
						</div>

						<div class="alert-form" v-if="alert.wrongReasone">
							Please make a selection
						</div>
					</div>

					<div class="btn-wrap deactive_button_wrap padding-model-box">
						<a @click="runCancelSubscription()" class="btn-red"
						>Yes, CANCEL SUBSCRIPTION</a
						>
					</div>
				</div>
				<div
						class="pop_up_closer"
						v-on:click="changePopup('cancelSubCard', false)"
				></div>
			</div>
		</transition>

		<transition name="fade">
			<div
					class="popup_wrpr overlay-bg active deactivate_popup"
					v-if="cancelSub"
			>
				<div
						class="popup save_popup upgrade-popup my-account"
						style="padding: 60px 25px 70px !important"
				>
					<a
							class="close_popup_btn"
							v-on:click="changePopup('cancelSub', false)"
					>
						<span class="inch-icon inch-close-icon"></span>
					</a>
					<div
							:style="{
							width: '100%',
							'text-align': 'center',
							margin: ' 20px 0',
						}"
					>
						<img src="@/assets/2uHnZAc.png" />
					</div>
					<p class="d_title" :style="{ color: '#000 !important' }">
						We're sad to see you go!
					</p>
					<div
							class="d_content"
							style="
							line-height: 1.9;
							font-size: 18px;
							font-weight: 300;
							font-family: 'soleil', Arial, Helvetica, sans-serif;
						"
					>
						<p data-v-14320997 class="fot">
							Your Colorcinch Plus subscription has been cancelled effective immediately.
						</p>
						<p data-v-14320997 class="fot">
							Feel free to continue to use our free product and when you’re
							ready to give Colorcinch Plus another try, we’d love to have you
							back!
						</p>
					</div>
					<hr class="pop_line">

					<div class="btn-wrap deactive_button_wrap padding-model-box">
						<a
								href="/my-account"
								class="btn-orange"
								:style="{ width: '100%', 'text-align': 'center' }"
						>RETURN TO MY ACCOUNT</a>
					</div>
				</div>
				<div
						class="pop_up_closer"
						v-on:click="changePopup('cancelSub', false)"
				></div>
			</div>
		</transition>
		<transition name="fade">
			<div class="popup_wrpr active popup_up_down" v-if="popupState.upgradePopup">
				<UpgradePopup :allowOnlyAnnual="allowOnlyAnnual" />
				<div class="pop_up_closer" v-on:click="changePopup('upgradePopup', false)"></div>
			</div>
		</transition>
		<transition name="fade">
			<div class="popup_wrpr active popup_up_down" v-if="popupState.stripeCheckoutPopup">
				<StripeCheckoutPopup />
				<div class="pop_up_closer" v-on:click="changePopup('stripeCheckoutPopup', false)"></div>
			</div>
		</transition>
		<transition name="fade">
			<div class="popup_wrpr active" v-if="!USER">
				<SignIn />
				<div class="pop_up_closer" v-on:click="changePopup('signIn', false)"></div>
			</div>
		</transition>
	</div>
</template>

<script>
	import UpgradePopup from "@/components/UpgradePopup.vue";
	import StripeCheckoutPopup from "@/components/StripeCheckoutPopup.vue";
import Acc from "@/libs/acc.js";
import CGPStripe from "@/libs/cgp-stripe";
import GpayAndroid from "@/libs/gpay-android";
import FreePlanPopup from "@/components/FreePlanPopup.vue";
	import SignIn from "@/components/SignIn.vue";

import DropdownSelect from "@/components/DropdownSelect.vue";
import {
	CardNumber,
	CardExpiry,
	CardCvc,
	Card,
	createToken,
	createPaymentMethod,
	instance,
} from "vue-stripe-elements-plus";
import PromoCodeParser from "@/libs/promoCode";

export default {
	name: "MyAccauntPopup",
	components: { CardNumber, CardExpiry, CardCvc, Card, DropdownSelect, FreePlanPopup, UpgradePopup, StripeCheckoutPopup, SignIn },
	data() {
		return {

			is_twa: false,
			gpayAndroid: false,
			cgpstripe: false,
			user_card: {},
			alert: {
				wrongReasone: false,
			},

			reasoneLeave: {
				selectDropdown: "",
				options: [
					...this.shuffle([
						"I only needed to use it once",
						"I can’t afford it right now",
						"I don’t think the product is useful",
						"I no longer use this product",
						"It was too complicated or unclear to use",
						"There aren’t enough features",
						"It was too slow or didn’t work properly",
						"I found another tool that I like better",
						"Fraud",
					]),
					"Other reason",
				],
				select: "",
				value: "",
			},
			accountDeleted: false,
			cancelSub: false,
			deactivateMyAccountPopup: false,
			updateEmailError: "",
			updatePasswordError: "",

			card_info: {},
			user: {},
			acc: new Acc(),
			changeEmail: false,
			changePassword: false,
			updateCard: false,
			changeSubscriptionPlan: false,
			cancelSubCard: false,
			successMessage: false,
			errorMessage: false,
			warningMessage: false,
			expireWarningMessage: false,
			menuStatus: false,
			mobSubMenu: false,
			privacyBox: !localStorage.getItem("agreePrivacyPreferences"),
			planSelectedId: null,
			updateResubscribeFlag: false,
			runSubscribeOnUpdateCard: false,
			planSubscribes: [],
			successTextMessage: "",
			errorTextMessage: "",
			changeEmailData: {
				email: "",
				confirmEmail: "",
				password: "",
			},
			changePasswordData: {
				current: "",
				newPassword: "",
				confirmNewPassword: "",
			},
			updateCardData: {
				name: "",
			},

			verifyNumber: false,
			verifyExpiry: false,
			verifyCVC: false,
			stripePublicKey: stripe_pub, // "pk_test_pjaHBMeEKNEHwWTtWWc8GrXf00Xwm5gLxc",
			stripeOptions: {},
			formErrorsUpdateCard: {},
			formErrorsSubscribeCard: {},
			isLoading: false,
			promo_code: {
				data: null,
			},
		};
	},

	methods: {
		renderPlanDescription(plan) {
			const parse = new PromoCodeParser(this.promo_code, this.planSubscribes);
			return parse.replacePlanDescription(plan);
		},

		getPromocodeStyle() {
			const isCurrent = this.promo_code.data && this.isCurrentSelected();

			return {
				height: isCurrent ? 'auto' : 0,
				transition: '.2s',
				padding: isCurrent ? '21px' : '0px 21px',
				overflow: 'hidden',
				opacity: isCurrent ? 1 : 0,
				width: '88%',
				left: '25px'
			};
		},

		getChangedPrice() {
			const parse = new PromoCodeParser(this.promo_code, this.planSubscribes);
			const selectedPlan = this.getUserPlan;
			if(!selectedPlan) return parse.getChangedPrice();

			return selectedPlan.interval == 'year' ? (parse.getChangedPrice(true) * 12).toFixed(2) : parse.getChangedPrice();
		},

		isCurrentSelected() {
			const selectedPlan = this.planSubscribes.find(
				(pl) => pl.id == this.planSelectedId
			);
			if(!this.USER.isPremium){
				return false;
			}

			return !selectedPlan || selectedPlan.current;
		},

		getPromoSavingPrice() {
			const parse = new PromoCodeParser(this.promo_code, this.planSubscribes);
			return parse.getPromoSavingPrice();
		},

		getPromoCode() {
			return this.promo_code.data ? this.promo_code.data.id : "";
		},

		parsePlanPrice(plan = {}, isFull = false) {
			const parse = new PromoCodeParser(this.promo_code, this.planSubscribes);
			return parse.parsePlanPrice(plan, isFull);
		},

		shuffle(array) {
			let currentIndex = array.length,
				temporaryValue,
				randomIndex;

			while (0 !== currentIndex) {
				randomIndex = Math.floor(Math.random() * currentIndex);
				currentIndex -= 1;
				temporaryValue = array[currentIndex];
				array[currentIndex] = array[randomIndex];
				array[randomIndex] = temporaryValue;
			}

			return array;
		},

		leavePopUpStyle() {
			return {
				padding: `60px 25px ${
					this.reasoneLeave.selectDropdown == "active" ? "250px" : "70px"
				} 25px !important`,
			};
		},

		openDropdown(option) {
			this.reasoneLeave.selectDropdown =
				this.reasoneLeave.selectDropdown == "active" ? "" : "active";
			this.alert.wrongReasone = false;
		},

		selectDrop(value) {
			this.reasoneLeave.value = value;
			this.reasoneLeave.selectDropdown = "";
		},

		copyReferralURL() {
			const textBox = this.$refs.referralURL;
			textBox.select();
			document.execCommand("copy");

			if (window.getSelection) {
				window.getSelection().removeAllRanges();
			} else if (document.selection) {
				document.selection.empty();
			}
		},

		async sendUpdatedCard(runSubscribeOnUpdateCard) {
			this.isLoading = true;
			if(!this.updateCardData.name){
				this.formErrorsUpdateCard = {
					message : "Please enter the name as it appears on your card",
					type : "name"
				};
				this.isLoading = false;
				return false;
			}
			const payment_data = await this.cgpstripe.pay(this.updateCardData.name);

			if(payment_data.error){
				let type = ''
				if(payment_data.error.includes('card number')) type = 'cardNumber'
				if(payment_data.error.includes('security code')) type = 'cardCvc'
				if(payment_data.error.includes('expiration date')) type = 'cardExpiry'
				this.formErrorsUpdateCard = {
					message : payment_data.error,
					type : type
				};
				this.isLoading = false;
				return false;
			}
			console.log("payment data is ok ", payment_data);
			const res = await this.acc.updateCard({
				payment_method: payment_data.payment_method,
			});
			if(runSubscribeOnUpdateCard){
				const updated = await this.changeSubPlan(true);
				if(!updated)
				{
					exit;
				}
			}
			this.updateCard = false;
			this.isLoading = false;
			this.$store.dispatch("refreshUser");
			this.haveBeenSaved("Your changes have been saved.",3000);


//			if(false){
//				createToken(payload).then(async (result) => {
//					if (result.error) {
//						this.formErrorsUpdateCard.push(result.error.message);
//						this.isLoading = false;
//						return false;
//					}

//					if (result.token && result.token.card) {
//						var payment_method = await createPaymentMethod(
//							"card",
//							result.token.id
//						);

//						if (payment_method.error) {
//							this.formErrorsUpdateCard.push(payment_method.error.message);
//							this.isLoading = false;
//							return false;
//						}

//						if (result.success == false) {
//							this.formErrorsUpdateCard.push(result.message);
//							this.isLoading = false;
//							return false;
//						}

//						const res = await this.acc.updateCard({
//							payment_method: payment_method.paymentMethod.id,
//							card: result.token.card,
//						});

//						if (res.success == false) {
//							this.formErrorsUpdateCard.push(res.message);
//							this.isLoading = false;
//							return;
//						}

//						this.updateCard = false;
//						this.isLoading = false;
//						this.$store.dispatch("refreshUser");
//						this.haveBeenSaved();
//					}
//				});
//			}
		},

		updateCardFocus() {
			this.complete = this.verifyNumber && this.verifyExpiry && this.verifyCVC;
			if (this.verifyNumber) {
				if (!this.verifyExpiry) {
					this.$refs.cardExpiry.focus();
				} else if (!this.verifyCVC) {
					this.$refs.cardCvc.focus();
				}
			} else if (this.verifyExpiry) {
				if (!this.verifyCVC) {
					this.$refs.cardCvc.focus();
				} else if (!this.verifyNumber) {
					this.$refs.cardNumber.focus();
				}
			}
		},
		changeCompleteCard(key, value) {
			this[key] = value;
		},
		inputCardName(e) {
			this.updateCardData.name = e.target.value;
		},

		async onChangePassword() {
			// TODO: changeEmailData
			this.updatePasswordError = null;

			if (this.changePasswordData.newPassword.length < 6) {
				this.updatePasswordError = "Password must be at least 6 characters";
				return false;
			}

			if (
				this.changePasswordData.newPassword !=
				this.changePasswordData.confirmNewPassword
			) {
				this.updatePasswordError = "Passwords do not match";
				return false;
			}

			const result = await this.acc.updateUser(
				"password",
				this.changePasswordData.newPassword,
				this.changePasswordData.current
			);

			if (result.success) {
				this.changePassword = false;
				this.haveBeenSaved();
			} else {
				this.updatePasswordError = result.message;
			}
		},

		async updateEmail() {
			// TODO: changeEmailData
			this.updateEmailError = null;
			const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

			if (!re.test(this.changeEmailData.email)) {
				this.updateEmailError = "Incorrect email";
				return false;
			}
			if (this.changeEmailData.email != this.changeEmailData.confirmEmail) {
				this.updateEmailError = "Emails do not match";
				return false;
			}

			this.isLoading = true;
			const result = await this.acc.updateUser(
				"email",
				this.changeEmailData.email,
				this.changeEmailData.password
			);

			if (result.success) {
				this.changeEmail = false;
				this.warningMessage = true;

				if (this.USER) {
					this.$store.state.USER = {
						...this.USER,
						email: this.changeEmailData.email,
					};
				}

				this.haveBeenSaved();
			} else {
				this.updateEmailError = result.message;
			}

			this.isLoading = false;
		},

		async resendVerificationEmail() {
			this.isLoading = true;
			const result = await this.acc.resendVerificationEmail();
			this.isLoading = false;
			//			this.warningMessage = false;
			this.haveBeenSaved("Email sent. Please check your inbox", 5000);
		},

		haveBeenSaved(text, time = 3000) {
			this.successTextMessage = text;
			this.successMessage = true;
			setTimeout(() => {
				this.successMessage = false;
				this.successTextMessage = null;
			}, time);
		},

		isHaveCard() {
			return this.USER && this.USER.card;
		},

		hideSuperAdmin(fields = []) {
			return this.USER && this.USER.role != "superadmin";
		},

		agreePrivacyPreferences() {
			localStorage.setItem("agreePrivacyPreferences", true);
			this.privacyBox = false;
		},

		async runDeactivate() {
			if (!this.reasoneLeave.value) {
				this.alert.wrongReasone = true;
				return;
			}

			await this.acc.removeAccount({ reason: this.reasoneLeave.value });
			this.accountDeleted = true;
			this.changePopup('deactivateMyAccountPopup', false);
		},

		async runCancelSubscription() {
			if (!this.reasoneLeave.value) {
				this.alert.wrongReasone = true;
				return;
			}

			await this.acc.cancelSubscription({ reason: this.reasoneLeave.value });
			this.changePopup('cancelSubCard', false)
			this.cancelSub = true;
		},

		async deactivateMyAccount() {
			this.deactivateMyAccountPopup = true;
			//			if (confirm("Are you sure you want to delete your account?")) {
			//				await this.acc.removeAccount();
			//				window.location.href = "/";
			//			}
		},

		async changePopup(popupName, status) {
			switch (popupName) {
				case "changeSubscriptionPlan":
					await this.changePlanPopup();
					this.formErrorsSubscribeCard = {};
					break;
				case "cancelSubCard":
					this.changeSubscriptionPlan = false;
					break;
				case "cancelSub":
					this.changeSubscriptionPlan = false;
					this.$store.dispatch("refreshUser");
					break;
				case "upgradePopup":
					this.$store.dispatch("refreshUser");
					break;
				case 'updateCard':
					if(status===true){
						console.log("stripe init 1");
						this.cgpstripe = new CGPStripe(this.$refs);
					}
					this.formErrorsUpdateCard = {};
				break;
				case 'accountDeleted':
					window.location.href = "/";
					break;
			}
			this[popupName] = status;
		},

		closeMessage(messageName) {
			this[messageName] = false;
		},

		toggleMenu() {
			this.menuStatus = !this.menuStatus;
		},

		subMenuToggle() {
			this.mobSubMenu = !this.mobSubMenu;
		},

		closePrivacy() {
			this.privacyBox = false;
		},

		setPromoCode() {
			if (this.getUserPlan && this.getUserPlan.coupon) {
				this.promo_code.data = this.getUserPlan.coupon;
			} else {
				this.promo_code.data = false;
			}
		},

		async changePlanPopup() {
			this.planSubscribes = await this.acc.getPlans();
			this.$store.state.plans = this.planSubscribes
		},

		async changeSubPlan(updateResubscribeFlag) {
			if(updateResubscribeFlag)
			{
				this.planSelectedId = this.USER.plan_id;
			}
			if(this.is_twa || window.debug_twa){
				console.log("this.planSubscribes", this.planSubscribes, this.planSelectedId);
				this.gpayAndroid = new GpayAndroid(this.$store, this);
				let plan = this.planSubscribes.find((el)=>{
					if(el.id==this.planSelectedId){
						return el;
					}
				});
				this.gpayAndroid.setPlanID(plan.google_id);
				let data1 =  await this.gpayAndroid.buyPlan(plan.google_id, true);

				let payment_info = {
					token: data1.token,
					payerData: data1.payerData,
					plan_id: plan.google_id,
				}

				try{
					this.isLoading = true;
					let resp = await fetch("/android/update-subscribe", {
						method: 'POST',
						headers: {'X-CSRF-TOKEN': document.querySelector("[name='csrf-token']").content, 'Content-Type': 'application/json'},
						body: JSON.stringify(payment_info)
					}).then((resp)=>{ return resp.json(); });

					console.log("resp is ", resp);
					if(resp.success){
						this.planSubscribes = this.planSubscribes.map((plan) => {
							if(plan.id == this.planSelectedId) {
								plan.current = true;
							}else{
								plan.current = false;
							}
							return plan;
						});
						this.changeSubscriptionPlan = false;
						this.haveBeenSaved();
						console.log("change ok???");
					}else{
						console.log(resp);
						alert("GPAY Error 1");
					}
					console.log("=== after subscribe ===", payment_info, resp);
				}catch(e){
					console.error("gpay error", e);
					alert("GPAY Error 2");
				}
				this.isLoading = false;

			}else{
				this.isLoading = true;
				if(this.planSelectedId == null){
					this.formErrorsSubscribeCard = {
						message : "Please select a subscription plan",
						type : "name"
					};
					this.isLoading = false;
					return false;
				}
				const result = await this.acc.changePlan(this.planSelectedId);
				if (result.success) {
					this.planSubscribes = this.planSubscribes.map((plan) => {
						if (plan.id == this.planSelectedId) {
							console.log("zzzz");
							plan.current = true;
						} else {
							plan.current = false;
						}
						return plan;
					});
					this.planSubscribes = await this.acc.getPlans();

					this.changeSubscriptionPlan = false;
					this.$store.dispatch("refreshUser");
					this.haveBeenSaved("Your changes have been saved and your subscription is now active.");
					this.isLoading = false;
					return true;
				} else {
					this.formErrorsSubscribeCard = {
						message : "Something went wrong. Please update your payment information and try again.",
						type : "name"
					};
					this.formErrorsUpdateCard = {
						message : "Something went wrong. Please update your payment information and try again.",
						type : "name"
					};

					this.isLoading = false;
					return false;
				}

				this.isLoading = false;
				// location.reload();
			}
		},

		changeSelectedPlan(e) {
			this.planSelectedId = Number(e.target.value);
		},

		isCurrentPlan(plan) {
			try{
				if(!this.USER.isPremium && this.USER.subscribeDetails.payment_status == "past_due" && this.USER.plan_id == plan.id)
				{
					return true;
				}
				else if(!this.USER.isPremium){
					return false;
				}else{
					return ((this.planSelectedId && this.planSelectedId == plan.id) || plan.current	);
				}
			}catch(e){
				return false;
			}
		},
	},
	computed: {
		USER() {
			return this.$store.state.USER;
		},
		getPlanSubscribes(){
			return this.planSubscribes;
		},
		getUserPlan(){
			return this.planSubscribes.find((plan) => plan.current);
		},
		popupState() {
			return this.$store.getters.POPUPS;
		},
		getLast4Card() {
			this.user_card = this.getUserCard;
			this.card_info = this.getUserCard;
			return this.user_card ? this.user_card.last4 || "" : "";
		},
		getUserCard() {
			if (this.USER && this.USER.card) {
				try {
					return JSON.parse(this.USER.card);
				} catch (err) {
					return {};
				}
			}

			return {};
		},
		allowOnlyAnnual() {
			return parseInt(this.$store.state.INFO?.allow_only_annual_plan) ?? 0;
		},
	},
	watch: {
		getPlanSubscribes() {
			this.setPromoCode();
		},

		USER(user) {
			this.warningMessage = !(user && user.email_verified_at);
			this.expireWarningMessage = user.expireSoon;
			this.errorMessage = (user && user.subscribeDetails && (user.subscribeDetails.payment_status === 'past_due' || (user.subscribeDetails.canceled && !user.is_user_cancel)));
			if(this.errorMessage) {
				this.errorTextMessage = user.subscribeDetails.payment_status === 'past_due' ? "Your plan has been paused. We were unable to charge you for your subscription. Please update your payment information to resume it.": "Your plan has been canceled. We were unable to charge you for your subscription. Please update your payment information to resubscribe.";
			}
			this.changePlanPopup()
		},

		verifyNumber() {
			this.updateCardFocus();
		},

		verifyExpiry() {
			this.updateCardFocus();
		},

		verifyCVC() {
			this.updateCardFocus();
		},
	},

	beforeMount() {
		if (!this.$store.state.USER) {
      this.$router.push({ name: 'home', query: { redirect: '/my-account' } });
			//			window.location = "/";
			//			return false;
		}
	},

	async mounted() {
		this.changePlanPopup();
		this.is_twa = 'getDigitalGoodsService' in window;

		//this.cgpstripe = new CGPStripe(this.$refs);

		//TODO обновить пароль/мыло
		//this.acc.updateUser("email", "test@test.aa");
		//this.acc.updateUser("password", "test@test.aa");

		//TODO удалить аккаунт
		//this.acc.removeAccount();

		//this.acc.changePlan(1);

		this.acc.getCardInfo().then((data) => {
			this.card_info = data.card;
			this.user = data.user;
		});

		this.warningMessage = !(this.USER && this.USER.email_verified_at);
		if(this.USER){
			this.expireWarningMessage = this.USER.expireSoon;
		}
		this.errorMessage = (this.USER && this.USER.subscribeDetails && (this.USER.subscribeDetails.payment_status === 'past_due' || (this.USER.subscribeDetails.canceled && !this.USER.is_user_cancel)));
		if(this.errorMessage) {
			this.errorTextMessage = this.USER.subscribeDetails.payment_status === 'past_due' ? "Your plan has been paused. We were unable to charge you for your subscription. Please update your payment information to resume it.": "Your plan has been canceled. We were unable to charge you for your subscription. Please update your payment information to resubscribe.";
		}

		document.body.style.overflow = "visible";
		document.body.style.position = "relative";

	},
	beforeDestroy() {
		document.body.style.overflow = "hidden";
	},
};
</script>
<style lang="scss">
#app .active.deactivate_popup {
	.d_title {
		/*color: #e64b4e;*/
		/*font-size: 28px;*/
		/*font-weight: 600;*/
		/*line-height: 1.3;*/
		/*text-align: center;*/

		padding: 0 10px;
		text-align: center;
		font: normal normal normal 24px/36px Soleil;
		letter-spacing: 0px;
		color: #FF5353;
	}

	.d_content {
		padding: 30px 10px;
		font: normal normal normal 16px/28px Soleil;
		text-align: center;
		/*padding-top: 30px;*/
		/* padding-bottom: 60px; */
		/*font-size: 18px;*/
		/*font-weight: 300;*/
		/*line-height: 34.2px;*/
		b {
			font-weight: bold;
		}
	}

	.deactive_button_wrap {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		.btn-red {
			display: inline-block;
			padding-left: 40px;
			padding-right: 40px;
			width: auto;
			text-transform: uppercase;
			background: #FF5353 0% 0% no-repeat padding-box;
			/*background-color: #e64b4e;*/
			/*font-size: 14px;
			font-weight: 600;*/
			font: normal normal normal 14px/18px Soleil;
			padding: 15px;
			color: #ffffff;
			-webkit-transition: 0.3s;
			transition: 0.3s;
			border-radius: 4px;
			&:hover {
				background-color: #cc4345;
			}
		}

		.under_button {
			/*padding-top: 10px;*/
			/*font-size: 13px;*/
			color: #FF5353;
			font: normal normal normal 14px/34px Soleil;
		}
	}

	.pop_line {
		top: 601px;
		left: 568px;
		width: 320px;
		height: 0px;
		/* UI Properties */
		border: 1px dashed #ECECEC;
		opacity: 1;
	}
}

header {
	.logo {
		.pc {
			display: block;
		}
		.mob {
			display: none;
		}
	}
}

.privacy_preferences_box {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 23px 45px 23px 36px;
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	background: #fff;
	z-index: 99;

	.close {
		width: 10px;
		height: 10px;
		position: absolute;
		right: 15px;
		top: 15px;

		&:hover {
			opacity: 0.7;
		}
	}


	p {
		font-size: 16px;
		color: #1d1d1d;
		font-weight: 600;
	}

	.right_box {
		flex-shrink: 0;
		margin-left: 38px;

		.privacy {
			margin-right: 30px;
			color: #1d1d1d;
			font-weight: 600;
			text-decoration: underline;
		}

		.btn {
			padding: 10.5px 25px;

			&:hover {
				box-shadow: 0 8px 35px -5px rgba(224, 90, 0, 0.6);
			}
		}
	}
}

footer.myacc {
	padding: 60px 0 42px 0;
	background: #1d1d1d;
	color: #fff;

	.rights_reserved {
		font-size: 14px;
		font-weight: 300;
		color: #8c8c8c;
		line-height: 26px;
		margin-top: 15px;
	}

	.logo {
		max-width: 136px;
		margin-top: 38px;
	}

	.wrapper_col {
		display: flex;
		margin-left: -20px;
		margin-right: -20px;

		.col-5 {
			width: 20%;
			padding-top: 15px;
			padding-left: 20px;
			padding-right: 20px;

			&:nth-child(1) {
				margin-right: 100px;
			}

			&:nth-child(5) {
				width: 25%;
			}

			.title {
				font-size: 18px;
				margin-bottom: 20px;
				font-weight: 600;
			}

			.menu {
				li {
					margin-bottom: 15px;

					&:last-child {
						margin-bottom: 0;
					}

					a {
						font-weight: 600;
						color: #8c8c8c;

						&:hover {
							color: #ffffff;
						}
					}
				}
			}

			.form_subscribe {
				position: relative;

				input {
					border-left: none;
					border-right: none;
					border-top: none;
					border-bottom: 1px solid rgba(255, 255, 255, 0.2);
					outline: none;
					padding-bottom: 10px;
					width: 100%;
					background: none;
					color: #fff;
				}

				button {
					position: absolute;
					right: 0;
					top: 50%;
					transform: translate(0, -50%);
					width: 17px;
					height: 17px;
					background: url("../assets/img/arrow_footer.svg") no-repeat;
					transition: all 0.3s cubic-bezier(0.2, 0, 0.3, 1);
					border: none;
					outline: none;

					&:hover {
						opacity: 0.7;
						transition: all 0.3s cubic-bezier(0.2, 0, 0.3, 1);
					}
				}
			}
		}
	}

	.bottom {
		margin-top: 50px;
		padding-top: 35px;
		border-top: 1px solid #2a2a2a;

		.menu_b {
			display: flex;
			align-items: center;
			justify-content: flex-end;

			li {
				margin-right: 20px;

				&:last-child {
					margin-right: 0;
				}

				a {
					color: #8c8c8c;
					text-decoration: underline;
				}
			}
		}
	}

	.mob_box {
		display: none;
	}
}

.main_title {
	padding: 32px 0;
	text-align: center;
}

.form {
	.item {
		padding: 30px;
		background: #fff;
		border: 1px solid #ececec;
		border-radius: 4px;

		+ .item {
			margin-top: 20px;
		}

		.head {
			margin-bottom: 20px;

			.title {
				font-size: 21px;
				font-weight: 600;
				color: #2a2a2a;
				padding-bottom: 20px;
				/*border-bottom: 1px dotted #ececec;*/
			}
		}

		.body {
			.line {
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding: 20px 0;

				.left_box {
					display: flex;
					width: 100%;

					label {
						font-weight: 400;
						color: #8c8c8c;
						font-size: 18px;
						min-width: 130px;
						display: inline-block;
					}

					input {
						border: none;
						outline: none;
						font-size: 16px;
						width: 100%;
					}

					.text {
						font-weight: 400;
						font-size: 16px;

						span {
							margin-right: 10px;
						}

						.content {
							color: #999999;
							font-size: 13px;
						}
					}
				}

				.edit {
					color: #fa6400;
					text-transform: uppercase;
					font-size: 14px;
					font-weight: 600;
					display: inline-flex;

					img {
						width: 16px;
					}
				}
			}

			.content {
				color: #2a2a2a;
				font-size: 16px;
				line-height: 1.9;
				font-weight: 400;
				.bold-color {
					color: red;
				}
			}

			.btn {
				&.btn_border {
					border: 1px solid #ecf0f3;
					background: none;
					color: rgba(45, 53, 67, 1);
					margin-top: 20px;

					&:hover {
						color: rgba(45, 53, 67, 1);
						background-color: rgba(255, 255, 255, 1);
						border: 1px solid rgba(255, 255, 255, 0);
						border-radius: 6px;
						box-shadow: 0 8px 35px -5px rgba(9, 31, 67, 0.15);
					}
				}
			}
		}
	}
}

main {
	padding: 84px 0;
}

.wrapper_main {
	max-width: 650px;
	margin: 0 auto;
}
.message {
	padding: 15px 18px;
	//box-shadow: 0 8px 40px -5px rgba(9, 31, 67, 0.15);
	color: #fff;
	border-radius: 4px;
	font-weight: 400;
	font-size: 16px;
	display: flex;
	align-items: center;
	justify-content: space-between;

	.close {
		width: 8px;
		height: 8px;
		background: transparent url('../assets/svg/notification_close.svg') 0% 0% no-repeat padding-box;
		flex-shrink: 0;

		svg {
			width: 20px;
			height: 20px;

			path {
				transition: all 0.3s cubic-bezier(0.2, 0, 0.3, 1);
			}

			&:hover path {
				opacity: 1;
				transition: all 0.3s cubic-bezier(0.2, 0, 0.3, 1);
			}
		}
	}

	&.warning {
		/*background-color: #ffca33;*/
		background: #FFF1E6 0% 0% no-repeat padding-box;
		border: 1px solid #FC8619;
		border-radius: 6px;
	}

	&.success {
		/*background-color: #03cc8a;*/
		background: #EDFBF8 0% 0% no-repeat padding-box;
		border: 1px solid #4ADABA;
		border-radius: 6px;
	}
	&.error {
		background: #F6E1E1 0% 0% no-repeat padding-box;
		border: 1px solid #FF5353;
		border-radius: 6px;
	}

	+ .message {
		margin-top: 10px;
	}


	.message-content {
		text-align: left;
		font: normal normal normal 14px/15px Soleil;
		letter-spacing: -0.28px;

		&.warning {
			color: #FA6400;
		}
		&.success {
			color: #38A68D;
		}
		&.error {
			color: #FF5353;
			font: normal normal normal 14px/20px Soleil;
			width: 85%;
		}
		.resend-email {
			margin-left: 280px;
			text-align: left;
			text-decoration: underline;
			font: normal normal normal 14px/15px Soleil;
			letter-spacing: -0.28px;
		}
	}
}

.container {
	max-width: 1340px;
	margin: 0 auto;
	padding: 0 15px;
	width: auto;
}

a {
	transition: all 0.3s cubic-bezier(0.2, 0, 0.3, 1);

	&:hover {
		transition: all 0.3s cubic-bezier(0.2, 0, 0.3, 1);
	}
}

.btn {
	font-size: 16px;
	font-weight: 400;
	color: rgba(53, 53, 53, 1);
	padding: 12.5px 20px;
	border-radius: 3px;
	background-color: #fa6400;
	text-align: center;
	color: #fff;
	display: inline-flex;

	&:hover {
		background: #e05a00;
	}
}

header {
	background: #fff;
	border-bottom: 1px solid rgba(236, 236, 236, 1);

	.logo {
		max-width: 152px;
	}

	.wrapper_box {
		display: flex;
		align-items: center;
		justify-content: space-between;
		min-height: 71px;
	}

	.right_box {
		display: flex;
		align-items: center;

		.header_burder_mob {
			display: none;
		}

		.menu {
			ul {
				display: flex;
				align-items: center;

				li {
					margin-right: 1.5em;

					&:last-child {
						margin-right: 0;
					}

					a {
						font-size: 16px;
						font-weight: 600;
						color: rgba(53, 53, 53, 1);

						&:hover {
							color: rgba(250, 100, 0, 1);
						}
					}
				}
			}
		}

		.wrap_btn {
			display: flex;
			align-items: center;
			margin-left: 35px;

			a {
				margin-right: 16px;
				font-size: 16px;
				font-weight: 600;
				color: rgba(53, 53, 53, 1);
				display: flex;
				align-items: center;

				&.sign_in {
					&:hover {
						color: #fa6400;
					}

					svg {
						transition: all 0.3s cubic-bezier(0.2, 0, 0.3, 1);
					}

					&:hover svg path {
						fill: #fa6400;
						transition: all 0.3s cubic-bezier(0.2, 0, 0.3, 1);
					}
				}

				&:last-child {
					margin-right: 0;
				}

				.icon {
					width: 20px;
					height: 20px;
					margin-right: 5px;

					svg {
						width: 20px;
						height: 20px;
						display: flex;
					}
				}
			}
		}
	}
}

.mob_menu_box_none {
	display: none;
}

@media (max-width: 1024px) {
	footer .wrapper_col,
	footer .bottom {
		display: none;
	}

	.privacy_preferences_box {
		flex-direction: column;

		.left_box {
			margin-bottom: 15px;
		}

		.right_box {
			margin: 0;
		}
	}

	footer {
		padding: 45px 0 25px 0;

		.mob_box {
			display: block;
			color: #8c8c8c;
			text-align: center;

			.wrap {
				margin: 20px 0;
			}

			.menu_mob {
				display: flex;
				align-items: center;
				justify-content: center;
				margin-top: 5px;

				li {
					margin-right: 10px;

					&:last-child {
						margin-right: 0;
					}

					a {
						text-decoration: underline;
					}
				}
			}

			.logo {
				margin: 0 auto;
			}
			.content {
				font-weight: 400;
			}
		}
	}
}

@media (max-width: 767px) {
	.mob_menu_box {
		display: block;
		position: fixed;
		top: 0;
		left: 0;
		padding: 20px;
		width: 100%;
		height: 100vh;

		&.active {
			display: block;
		}

		form {
			margin: 30px 0 72px 0;
			position: relative;

			input[type="text"] {
				padding: 0 40px 0 20px;
				height: auto;
				width: 100%;
				border-radius: 60px;
				background-color: rgba(0, 0, 0, 0.05) !important;
				border: none !important;
				box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.05) !important;
				font-size: 16px;
				height: 38.4px;
				color: #fff;

				&::-webkit-input-placeholder {
					color: #767676;
				}

				&::-moz-placeholder {
					color: #767676;
				}

				/* Firefox 19+ */
				&:-moz-placeholder {
					color: #767676;
				}

				/* Firefox 18- */
				&:-ms-input-placeholder {
					color: #767676;
				}
			}

			button {
				position: absolute;
				right: 15px;
				top: 50%;
				transform: translate(0, -50%);
				border: none;
				outline: none;
				background: url("../assets/img/search_previous_icon.svg") no-repeat;
				width: 15px;
				height: 15px;
			}
		}

		.sign_in {
			display: flex;
			align-items: center;

			.icon {
				margin-right: 15px;
				width: 15px;
			}
		}

		.close {
			position: absolute;
			width: 40px;
			height: 40px;
			border-radius: 50%;
			background: #fff;
			right: 30px;
			top: 30px;
			display: flex;
			justify-content: center;
			align-items: center;
			transition: all 0.3s cubic-bezier(0.2, 0, 0.3, 1);

			img {
				width: 12px;
			}

			&:hover {
				transform: scale3d(1.1, 1.1, 1);
				transition: all 0.3s cubic-bezier(0.2, 0, 0.3, 1);
			}
		}

		.overlay {
			position: absolute;
			width: 100%;
			height: 100%;
			background: rgba(0, 0, 0, 0.5);
			left: 0;
			top: 0;
			z-index: 10;
		}

		.wrapper_menu {
			width: 290px;
			height: 100vh;
			position: absolute;
			right: 0;
			top: 0;
			background: #1d1d1d;
			z-index: 20;
			box-shadow: 0 0 50px -5px rgba(9, 31, 67, 0.07);
			color: #fff;
			padding: 118px 35px 40px 30px;
			overflow-y: scroll;

			.menu {
				margin-bottom: 70px;

				li {
					a {
						width: 100%;
						display: flex;
						align-items: center;
						justify-content: space-between;
					}

					.icon {
						position: relative;
						width: 50px;
						height: 12px;

						&:before {
							content: "";
							position: absolute;
							top: 0;
							left: 50%;
							width: 3px;
							height: 3px;
							border-radius: 50%;
							transition: 0.5s;
							background: #fff;
						}

						&:after {
							content: "";
							position: absolute;
							bottom: 0;
							left: 50%;
							width: 3px;
							height: 3px;
							border-radius: 50%;
							transition: 0.5s;
							background: #fff;
						}

						&.toggle {
							&:before {
								top: 50%;
								width: 12px;
								height: 1px;
								border-radius: 0;
							}

							&:after {
								bottom: 50%;
								width: 12px;
								height: 1px;
								border-radius: 0;
							}
						}
					}

					a {
						font-size: 17px;
					}

					+ li {
						margin-top: 27px;
					}

					.sub_menu {
						display: none;
						margin-top: 27px;
						padding-left: 20px;

						&.open_sub_menu {
							display: block;
						}
					}
				}
			}

			.rights_reserved {
				font-size: 17px;
			}
		}
	}

	header {
		background: #1d1d1d;
		min-height: 60px;

		.logo {
			.pc {
				display: none;
			}
			.mob {
				display: block;
			}
		}

		.right_box {
			.header_burder_mob {
				display: block;
				width: 24px;

				.line {
					width: 100%;
					height: 4px;
					background: #fff;

					+ .line {
						margin-top: 3px;
					}
				}
			}

			.menu,
			.wrap_btn {
				display: none;
			}
		}
	}

	.form .item .body .line .left_box {
		flex-direction: column;
		align-items: flex-start;
	}

	.form .item .body .line .left_box .text .content {
		line-height: 19px;
	}

	.form .item .btn.btn_border {
		margin-top: 20px;
	}

	.form .item .body .content {
		margin-bottom: 0;
	}

	.form .item .body .line {
		align-items: flex-end;
	}

	.form .item .body .line .left_box label {
		min-width: auto;
		margin-bottom: 30px;
	}
}

@media (max-width: 640px) {
	main {
		padding: 20px 0 84px 0;
	}

	.privacy_preferences_box {
		text-align: center;

		p {
			font-size: 16px;
			font-weight: 500;
			line-height: 27px;
		}

		.right_box {
			display: flex;
			flex-direction: column;
			align-items: center;

			.privacy {
				margin-bottom: 15px;
				margin-right: 0;
			}
		}
	}
}
</style>

<style lang="scss">
.container {
	font-family: "soleil";
}

.white-layout {
	min-height: 100vh;
	background: #f5f5f5;
}
</style>

<style scoped>
	.form-group > .label.update-card {
		padding: 0px 0px 0px 5px;
	}
	.form-group > .label {
		padding: 0px 2px 5px 2px;
	}


.padding-model-box {
	padding: 0px 30px 0px 30px;
}

#app .active.deactivate_popup .deactive_button_wrap .btn-red {
	width: 100% !important;
	text-align: center;
}

.d_content > .fot {
	/*font-size: 18px !important;*/
	font: normal normal normal 16px/28px Soleil !important;
	color: #727272 !important;
}

header .right_box .menu ul li a {
	font-weight: 400;
}

.promo_code_info {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	background: #a4ecdc 0% 0% no-repeat padding-box;
	border-radius: 5px;
	padding: 21px;
	margin-bottom: 12px;
	position: relative;
	width: 100%;
}
.error_subscription_popup {
	margin-left: 33px;
	color: #FF5353;
}

.promo_code_info > .left {
	flex: 1;
	text-align: left;
}

.promo_code_info > .left > ._code {
	letter-spacing: 0.2px;
	color: #2a2a2a;
	font: normal normal 600 14px/17px Proxima Nova;
	margin-right: 4px;
}

.my-account.transition {
	transition: 0.2s;
}

.promo_applied {
	position: absolute;
	right: 0;
	top: -3px;
	background: #4adaba;
	padding: 6px;
	border-radius: 0px 5px 3px 3px;
	text-align: left;
	font: normal normal 600 9px/11px Proxima Nova !important;
	letter-spacing: 0px;
	color: #ffffff !important;
	text-transform: uppercase;
	opacity: 1;
}

.btn-orange.disabled {
	background: rgba(250, 100, 0, 0.5);
}

.decoration {
	text-decoration: line-through;
}
</style>
