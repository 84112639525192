<template>
	<div class="item freeplan">
		<div class="head freeplan">
			<div class="title freeplan">You're on the Free plan</div>
		</div>
		<div class="body freeplan">
			<div class="line-free">
				<div class="left_box">
					<div class="text">
						<div class="title">
							Get Colorcinch Plus for as little as $6.99 per month to unlock:
						</div>
					</div>
				</div>
				<div class="right_box">
					<a href="#" class="icon edit"  v-on:click="upgradePlan()">
						Subscribe Now
					</a>
				</div>
			</div>
			<div class="left_box">
				<div class="text">
					<div class="content">
						<ul class="list-plus">
							<li class="list">
								Thousands of artsy effects, filters and presets
							</li>
							<li class="list">
								Best in class photo editing tools
							</li>
							<li class="list">
								One-click background remover
							</li>
							<li class="list">
								Millions of stock photos and graphics
							</li>
							<li class="list">
								Unlimited projects
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>

	</div>
</template>
<script>
	import Acc from "@/libs/acc.js";
	// import {Card, CardCvc, CardExpiry, CardNumber} from "vue-stripe-elements-plus";
	export default {
		name: "FreePlanPopup",
		components: {
		},
		async mounted() {

			},
		data() {
			return {
				showNewPopup:false,
				acc: new Acc(),
				upgradePopup: false,
			}
		},
		computed: {
			popupState() {
				return this.$store.getters.POPUPS;
			},
		},
		methods:{
			upgradePlan() {
				// if (window.abVariant && window.abVariant == 2) {
					this.changePopup("stripeCheckoutPopup", true);
				// }
				// else{
				// 	this.changePopup("upgradePopup", true);
				// }
			},
			changePopup(property, value) {
				this.$store.dispatch("popupsToggle", { property, value });
			},
			// async changePopup(popupName, status) {
			// 	alert('thisd');
			// 	switch (popupName) {
			// 		case "showNewPopup":
			// 			if(status == true){
			// 				await this.changePlanPopup();
			// 			} else {
			// 				alert('thisd');
			// 				this.showNewPopup = false;
			// 			}
			// 			break;
			// 	}
			// 	this[popupName] = status;
			// },

		}
	};
</script>

<style scoped>
	.item.freeplan {
		background-image: url('../assets/svg/freeplan-background.svg');
		background-repeat: no-repeat;
		background-position: top 27px right -22px;
	}

	.head.freeplan {
		margin-bottom: 0px !important;
	}

	.title.freeplan {
		border-bottom: 1px dotted #ececec !important;
	}

	.right_box{
		width: 200px;
		text-align: right;
		font: normal normal normal 14px/15px Soleil;
		margin-top: 5px;
		letter-spacing: -0.28px;
		color: #FA6400;
		text-transform: uppercase;
		font-weight: 600;
	}
	.left_box .text .title{
		font-weight: 700;
		font: normal normal normal 18px/24px Soleil;
		padding: 0 10px 0 0;
	}
	.list-plus li:before {
		content: url('../assets/svg/plus.svg'); /* FontAwesome Unicode */
		font-family: icomoon;
		color: #fa9d1c;
		display: inline-block;
		margin-left: -20px; margin-right: 10px;
		width: 1.3em; /* same as padding-left set on li */
		margin-left: 18px;
	}
	.list{
		font: normal normal 300 16px/20px Soleil;
		color: #727272;
		padding: 5px;
	}
	.line-free {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
		padding: 20px 0;
	}

</style>
