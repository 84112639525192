<template>
	<div >
		<div class="form_loading" v-if="isLoading">
			<div class="lds-ring">
				<div></div>
				<div></div>
				<div></div>
				<div></div>
			</div>
		</div>
		<div class="popup save_popup upgrade-popup editorpage subs_popup" v-if="isOffline" style="background: #2A2A2A;">
			<a	class="close_popup_btn" v-on:click="changePopup('upgradePopup', false)">
				<span class="inch-icon inch-close-icon"></span>
			</a>

			<div class="is_offline">
				<Offline></Offline>
			</div>

		</div>

		<div class="popup save_popup upgrade-popup editorpage subs_popup"
			 :class="{'is_pwa_popup':is_pwa}"
			 v-if="confirmSubscriptionPopup && isOnline"
		>
			<a
					class="close_popup_btn"
					v-on:click="changePopup('upgradePopup', false)"
			>
				<span class="inch-icon inch-close-icon"></span>
			</a>
			<div class="icon">
				<img src="../assets/mobile/plus.svg" alt />
			</div>
			<div class="wrap_promo_plan">
				<div class="wrapper_box">
					<h2 class="title old_update_popup_1">Confirm Subscription</h2>
					<p class="all-plus-future">
						{{allowOnlyAnnual?'All Plus features – one low price. Pay by the year. Cancel anytime.':'All Plus features – one low price. Pay by month or the year. Cancel anytime.'}}
					</p>

				</div>
				<div class="link-wrap"></div>

				<div v-if="!allowOnlyAnnual" class="plan-type-wrap">
					<template v-for="(plan, index) in plans">
						<label
								class="plan-type editorpage"
								:key="index + promo_code.updater"
								v-if="(!plan.is_debug) || (plan.is_debug && is_debug_mode)"
						>
							<span
									class="promo_applied"
									v-if="
									promo_code.data &&
									selectedPlanId == plan.id &&
									plan.stripe_id == promo_code.data.plan_id
								"
							>Code applied</span
							>
							<input
									type="radio"
									name="plan"
									:value="plan.id"
									@input="selectPlan"
									:checked="selectedPlanId == plan.id"
							/>
							<div class="checkmark editorpage">
								<strong>{{ plan.title }} – {{ parsePlanPrice(plan) }}</strong>
								<span :class="{ decoration: parsePlanPrice(plan) == 'FREE' }">{{
									renderPlanDescription(plan)
								}}</span>
							</div>
						</label>
					</template>
					<a v-if="!is_pwa" class="promoButton" href="#" @click="openPromoInput()"
					>Have a promo code?</a>
				</div>
				<div v-else class="plan-type-wrap">
					<template v-for="(plan, index) in plans">
						<label
								class="plan-type editorpage"
								style="width: calc(100%)"
								:key="index + promo_code.updater"
								v-if="plan.title == 'Annual Plan' && ((!plan.is_debug) || (plan.is_debug && is_debug_mode))"
						>
							<span
									class="promo_applied"
									v-if="
									promo_code.data &&
									selectedPlanId == plan.id &&
									plan.stripe_id == promo_code.data.plan_id
								"
							>Code applied</span
							>
							<input
									type="radio"
									name="plan"
									:value="plan.id"
									@input="selectPlan"
									:checked="selectedPlanId == plan.id"
							/>
							<div class="checkmark editorpage">
								<strong>{{ plan.title }} – {{ parsePlanPrice(plan) }}</strong>
								<span :class="{ decoration: parsePlanPrice(plan) == 'FREE' }">{{
									renderPlanDescription(plan)
								}}</span>
							</div>
						</label>
					</template>
					<a v-if="!is_pwa" class="promoButton" href="#" @click="openPromoInput()"
					>Have a promo code?</a
					>
				</div>

				<div v-if="!is_pwa"
					 :style="{
						position: 'relative',
						'margin-top': '18px',
						'min-height': promo_code.data ? '70px' : 0,
					}"
				>
					<div
							class="promo_code_info"
							:style="{
							opacity: promo_code.data ? 1 : 0,
							'z-index': promo_code.data ? 999 : 'auto',
						}"
					>
						<div class="left">
							<span class="_code">{{ getPromoCode() }}</span> |
							<span class="_text">{{ getPromoSavingPrice() }}</span>
						</div>

						<div class="right">
							<a href="#" class="remove_promo_btn" @click="removePromoCode()"
							>Remove</a
							>
						</div>
					</div>

					<div
							class="form-group promo_code_block"
							:style="getPromoCodeBlockStyle"
					>
						<input
								:placeholder="promo_code.plac.text"
								:readonly="!promo_code.isOpenInput"
								@input="inputPromoCode"
								:class="{
								promo_error: promo_code.error,
								disabled: promo_code.loading,
							}"
								ref="promo_code_elem"
								type="text"
								class="form-control"
								v-model="promo_code.code"
						/>
						<a
								href="#"
								@click="checkPromoCode()"
								class="checkActivePromocode"
								:class="{ active: promo_code.code }"
						>
							<span
									v-if="!promo_code.loading"
									class="promo_button"
									:class="{ act: promo_code.code }"
							>
								<svg
										xmlns="http://www.w3.org/2000/svg"
										width="40"
										height="38"
										viewBox="0 0 40 38"
								>
									<g transform="translate(-616 -646)">
										<g
												fill="none"
												stroke="#ececec"
												class="_border"
												transform="translate(616 646)"
										>
											<rect stroke="none" width="40" height="38" rx="4" />
											<rect
													class="_bg"
													fill="none"
													x="0.5"
													y="0.5"
													width="39"
													height="37"
													rx="3.5"
											/>
										</g>
										<path
												fill="#fa6400"
												class="_arrow"
												d="M7.344,6.656l-.369.334L1.344,12.058l.739.845L7.344,8.169,12.6,12.9l.739-.845L7.713,6.991Z"
												transform="translate(645.902 657.656) rotate(90)"
										/>
									</g>
								</svg>
							</span>

							<span v-if="promo_code.loading" class="promo_loader">
								<div class="lds-ring">
									<div></div>
									<div></div>
									<div></div>
									<div></div>
								</div>
							</span>
						</a>
					</div>
				</div>
			</div>

			<div v-if="!isLoggedIn" class="form-group sign-up">
        <div class="signup-fields">
          <div class="label sign-up">Email</div>
          <input
              type="email"
              v-model="register.email"
              ref="reg_email"
              class="form-control input-drop-shadow"
          />
        </div>
        <div class="signup-fields">
          <div class="label sign-up">Password</div>
          <input
              type="password"
              v-model="register.password"
              ref="reg_password"
              class="form-control input-drop-shadow"
          />
        </div>

			</div>

			<div
					class="card-data"
					ref="card_1">
				<div class="form-group" v-if="!is_pwa">
					<div class="label editorpage">Name on Card</div>
					<input
							type="text"
							v-model="register.name"
							value="test111"
							class="form-control input-drop-shadow"
							@input="changeCardName"
					/>
				</div>
				<div class="form-group-flex" v-if="!is_pwa">
					<div class="form-group col-6">
						<div class="label editorpage">Card Number</div>
						<div class="input-drop-shadow" id='cardNumber' ref='cardNumber'></div>
					</div>
					<div class="form-group col-4">
						<div class="label editorpage">Exp. Date</div>
						<div class="input-drop-shadow" id='cardExpiry' ref='cardExpiry'></div>
					</div>
					<div class="form-group col-4">
						<div class="label editorpage">CVC</div>
						<div class="input-drop-shadow" id='cardCvc' ref='cardCvc'></div>
					</div>
				</div>
			</div>
			<!--			<label class="checkbox">-->
			<!--				<input type="checkbox" v-model="agree_terms" />-->
			<!--				<span class="check"></span>-->
			<!--				I agree to the Colorcinch-->
			<!--				<a :href="$store.state.domain + `/privacy-policy/`" target="_blank">-->
			<!--					Privacy Policy-->
			<!--				</a>-->
			<!--				and-->
			<!--				<a :href="$store.state.domain + `/terms/`" target="_blank">Terms.</a>-->
			<!--			</label>-->


			<div class="form-group test_gpay" v-if="is_pwa">
				<div id="gpayButton" ref="gpayButton"></div>
			</div>



			<div class="form_errors" v-if="formErrors">
				<ul id="example-1">
					<li v-for="item in formErrors">{{ item }}</li>
				</ul>
			</div>
			<div
					v-if="!is_pwa"
					class="btn-wrap"
					:style="{ 'margin-top': '15px' }"
			>
				<a href="#" @click="payStripe" class="btn-orange" style="color: #fff;margin:0;">Subscribe</a>
			</div>

			<div v-if="!this.$store.state.USER" class="member_sign_in">
				Already a member? <a href="#" @click.prevent="showLoginPopUp"><u>Sign in</u></a>
			</div>

			<div class="agreement_detail">
				<p>By confirming your subscription, you agree to the Colorcinch <a href="https://www.cartoonize.net/privacy-policy/"><u>Privacy Policy</u></a> and <a href="https://www.cartoonize.net/terms/"><u>Terms of Service</u></a>.</p>
			</div>
		</div>

		<div class="popup save_popup upgrade-popup small width-padding" v-if="congratPopup">
			<a class="close_popup_btn" v-on:click="closeAllPopups()">
				<span class="inch-icon inch-close-icon"></span>
			</a>
			<div class="icon margin-bottom">
				<img src="../assets/images/sun.png" alt />
			</div>
			<h2
					class="title sm title-success-signin"
					:style="{
					'font-weight': '600 !important',
					'font-size': '28px !important',
				}"
			>
				Thanks for joining! We're thrilled to have you!
			</h2>
			<p class="box-success-signin">
				You now have full access to Colorcinch Plus and everything it has to
				offer!
			</p>
			<!-- :style="{ 'margin-top': '120px !important' }" -->
			<div class="btn-wrap margin-top" >
				<a :href="$route.name === 'my account'?'/':'#'" @click="closeAllPopups" class="btn-orange" style="margin: 0; color: #fff;">Start Editing</a>
			</div>
			<span class="sm-descr">
				or visit
				<a href="/my-account">My Account</a> instead
			</span>
		</div>
	</div>
</template>
<script>
	//import {
	//	CardNumber,
	//	CardExpiry,
	//	CardCvc,
	//	Card,
	//	createToken,
	//	createPaymentMethod,
	//	instance,
	//	customers,
	//} from "vue-stripe-elements-plus";


	import CGPStripe from "@/libs/cgp-stripe";
	import Gpay from "@/libs/gpay";

	import Offline from "@/components/Offline.vue";
	import DropdownSelect from "@/components/DropdownSelect.vue";
	import PromoCodeParser from "@/libs/promoCode";

	const analitics = require("../libs/analytics");

	export default {
		name: "UpgradePopup",
		components: { DropdownSelect, Offline },
		props: {
			yesUpgrade: Function,
			allowOnlyAnnual:  {
				type: Number,
				default: 0
			},
		},
		async mounted(){
			console.log("upgrade popup mounted");
			document.addEventListener("gpay_token_generated", (e)=>{
				let {paymentToken, promocode} = e.detail;
				this.gpay_promo  = promocode;

				if(this.$store.state.USER){
					this.paymentMethodDetails = paymentToken;
					if(this.$store.state.USER.status === 'free') this.updateGTMEvent('add_payment_info','free_user')
					this.createUserAndSubscribe(true);
				}else{
					this.paymentMethodDetails = paymentToken;
					this.updateGTMEvent('add_payment_info','visitor')
					this.createUserAndSubscribe();
				}

				//this.onGpayToken();
			});

		},
		created() {
			// this.confirmSubscriptionPopup = false;


			this.plans = this.$store.state.plans || [];
			if (this.plans.length) {
				this.selectedPlanId = this.plans[0].id;
			}

			//TODO uncomment
			this.is_pwa = window.matchMedia('(display-mode: standalone)').matches && typeof PaymentRequest!='undefined';

			this.is_debug_mode = document.cookie.indexOf('debug_payments_1=')===-1?false:true;

			this.$nextTick(()=>{
				if(this.is_pwa){
					this.gpay = new Gpay(this.$store, this);
					console.log("this.$refs", this.$refs);
					this.gpay.init(this.$refs.gpayButton, this.selectedPlanId);
				}else{
					this.cgpstripe = new CGPStripe(this.$refs);
				}
			})
		},

		data: function () {
			return {
				gpay_token_generated: false,
				is_pwa: false,
				is_debug_mode: false,
				cgpstripe: false,
				gpay_promo: "",
				promo_code: {
					code: "",
					isFree: false,
					isOpenInput: false,
					loading: false,
					data: false,
					updater: 1,
					plac: {
						text: "Enter Promo Code",
						default: "Enter Promo Code",
						error: "Oops! The code you entered doesn't exist.",
					},
				},

				agree_terms: false,
				register: {
					name: "",
					email: "",
					email_confirm: "",
					password: "",
					howUse: "",
				},
				formErrors: [],
				paymentMethodDetails: {},
				confirmSubscriptionPopup: true,
				congratPopup: false,
				stripe: stripe_pub, //"pk_test_pjaHBMeEKNEHwWTtWWc8GrXf00Xwm5gLxc",
				complete: false,
				number: false,
				expiry: false,
				cvc: false,
				cardName: "",
				isLoading: false,
				formErrors: [],
				plans: [],
				selectedPlanId: null,
				options: {
					elements: {
						fonts: [{}],
					},
					style: {
						base: {
							fontFamily: "Proxima Nova",
						},
					},
				},
			};
		},

		methods: {

//		onGpayToken(){
//			if(this.$store.state.paymentToken && this.$store.state.paymentToken.pwa){
//				if(this.$store.state.USER){
//					this.paymentMethodDetails = this.$store.state.paymentToken;
//					this.createUserAndSubscribe(true);
//				}else{
//					this.paymentMethodDetails = this.$store.state.paymentToken;
//				}
//			}
//			return this.$store.state.paymentToken;
//		},

			renderPlanDescription(plan) {
				const parse = new PromoCodeParser(this.promo_code, this.plans);
				return parse.replacePlanDescription(plan);
			},

			getPromoSavingPrice() {
				const parse = new PromoCodeParser(this.promo_code, this.plans);
				return parse.getPromoSavingPrice();
			},

			getPromoCode() {
				return this.promo_code.code;
			},

			parsePlanPrice(plan = {}, isFull = false) {
				const parse = new PromoCodeParser(this.promo_code, this.plans);
				return parse.parsePlanPrice(plan, isFull);
			},

			removePromoCode(isOpenInput = false, focusInput = false) {
				this.promo_code.data = false;
				this.promo_code.isOpenInput = isOpenInput;
				this.promo_code.error = false;
				this.promo_code.code = "";

				if (focusInput) {
					this.$refs.promo_code_elem.focus();
				}
			},

			async checkPromoCode() {
				if (!this.promo_code.code) return;
				if (this.promo_code.loading) return;
				this.promo_code.loading = true;

				try {
					const response = await fetch(
							`${window.prefixURL}/check_promocode?code=${this.promo_code.code}`
					).then((res) => res.json());
					if (response.success) {
						const plan = this.plans.find(
								(pl) => pl.stripe_id == response.plan_id
						);
						if (!plan) throw Error("Bad code");

						this.selectedPlanId = plan.id;
						this.promo_code.data = response;
						this.promo_code.updater += 1;
					} else {
						throw Error("Bad code");
					}
				} catch (err) {
					this.promo_code.plac.text = this.promo_code.plac.error;

					console.error(err);
					this.promo_code.error = true;
					this.promo_code.code = "";
					this.$refs.promo_code_elem.focus();
				}

				this.promo_code.loading = false;
			},

			inputPromoCode() {
				this.promo_code.error = false;
				this.promo_code.plac.text = this.promo_code.plac.default;
			},

			openPromoInput() {
				if (this.promo_code.data) {
					this.removePromoCode(true, true);
				} else {
					this.promo_code.isOpenInput = !this.promo_code.isOpenInput;
				}
			},

			showLoginPopUp() {
				this.closeAllPopups();
				this.$store.dispatch("popupsToggle", {
					property: "signIn",
					value: true,
				});
			},

			selectPlan(e) {
				this.promo_code.code = "";
				this.promo_code.isOpenInput = false;
				this.promo_code.loading = false;
				this.promo_code.data = false;
				this.promo_code.datupdatera += 1;

				this.selectedPlanId = e.target.value;
			},

			changeCardName(e) {
				this.cardName = e.target.value;
			},
			//TODO remove?
			async createCustomer(paymentMethod, card) {
				return fetch(`${this.$store.state.prefixURL}/create_customer`, {
					method: "post",
					headers: {
						"X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
						"Content-Type": "application/json",
					},
					body: JSON.stringify({
						payment_method: paymentMethod,
						card,
						promo_code: this.promo_code.code,
						plan_id: this.selectedPlanId,
					}),
				}).then((res) => res.json());
			},

			async createUserAndSubscribe(only_payment=false) {
				console.log("in createUserAndSubscribe");

				//paymentToken

				this.formErrors = null;
				if(only_payment===true){
					only_payment = true;
				}else{
					only_payment = false;
				}

				this.isLoading = true;
				var is_gpay  =false;

				if(this.paymentMethodDetails.pwa || this.is_pwa){

					var payment_data = {
						is_gpay: true,
						promo_code: this.gpay_promo,
						plan_id: this.selectedPlanId,
						...this.paymentMethodDetails
					};
					is_gpay = true;
				}else{
					var payment_data = {
						seti: this.paymentMethodDetails,
						promo_code: this.promo_code.code,
						plan_id: this.selectedPlanId,
						is_gpay: false,
					}
				}
				const result = await fetch(`${this.$store.state.prefixURL}/register3`, {
					method: "POST",
					body: JSON.stringify(
							Object.assign(this.register, {
								_token: $('[name="csrf-token"]').attr("content"),
								only_payment: only_payment,
								promo_code: this.promo_code.code,
								payment_data: payment_data,
								is_gpay: is_gpay,
							})
					),
					headers: {
						Accept: "application/json",
						"Content-Type": "application/json",
						"X-CSRF-TOKEN": $('[name="csrf-token"]').attr("content"),
					},
				}).then((res) => res.json());

				this.formErrors = result.errors || null;
				this.isLoading = false;


				if (result.success == false) {
					this.formErrors = [paymentRes.message];
					//window.location.reload();
					this.isLoading = false;
					this.confirmSubscriptionPopup = true;
					return;
				}
				result.data.user.isPremium = true;
				if(this.$store.state.USER){
					const subscriptionId = result.data.customer.subscription.id ?? null
					if(this.$store.state.USER.status === 'free') this.updateGTMEvent('purchase','free_user',subscriptionId);
				} else {
					this.updateGTMEvent('purchase','visitor',result.data.customer.subscription.id);
				}
				this.$store.state.USER = result.data.user;
				if(!only_payment) analitics.sendCustomEvent("add_account_info",{user_email:this.$store.state.USER.email});

				this.popupOpen("congratPopup");
				this.isLoading = false;
				await fetch("/createAffilate", {
					headers: {
						"X-CSRF-TOKEN": $('[name="csrf-token"]').attr("content"),
					},
				}).then((r) => r.text());
				rewardful("convert", { email: this.register.email });

				this.success_payment = true;

				return false;
			},
			async payStripe() {

				this.formErrors = [];
				if(!this.$store.state.USER) {
					if (
							!this.register.email ||
							!this.register.password
					) {
						this.formErrors = ["Please enter email and password"];
						return false;
					}

					if (
							!/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(
									this.register.email
							)
					) {
						this.formErrors = ["Please enter a valid email address"];
						return false;
					}


					if (this.register.password.length < 8) {
						this.formErrors = ["Your password must be at least 8 characters"];
						return false;
					}
				}
				if(!this.cardName){
					this.formErrors.push(
							"Please enter the name as it appears on your card"
					);
					return false;
				}

				this.isLoading = true;
				var payment_data = await this.cgpstripe.pay(this.cardName);
				if(payment_data.error){
					this.formErrors.push(payment_data.error);
					this.isLoading = false;
					return false;
				}
				//name


				this.paymentMethodDetails = payment_data;
				this.isLoading = false;

				console.log("1 this.paymentMethodDetails", this.paymentMethodDetails);
				if(this.$store.state.USER){
					console.log("createUserAndSubscribe 1");
					if(this.$store.state.USER.status === 'free') this.updateGTMEvent('add_payment_info','free_user')
					this.createUserAndSubscribe(true);
				}else{
					this.updateGTMEvent('add_payment_info','visitor')
					this.createUserAndSubscribe();
				}
				return false;

				//TODO old

				createToken({ name: this.cardName }).then(async (result) => {
					if (result.error) {
						this.formErrors.push(result.error.message);
						this.isLoading = false;
						return false;
					}
					if (result.token && result.token.card) {
						console.log("result.token.card", result.token);
						var payment_method = await createPaymentMethod(
								"card",
								result.token.id
						);
						console.log("payment.method", payment_method);

						if (payment_method.error) {
							this.formErrors.push(payment_method.error.message);
							this.isLoading = false;
							return false;
						}

						if (result.success == false) {
							this.formErrors.push(result.message);
							this.isLoading = false;
							return false;
						}

						this.paymentMethodDetails = {
							payment_method_id: payment_method.paymentMethod.id,
							card: result.token.card,
						};

						this.isLoading = false;
					}

				});

				// console.log(this.number, this.expiry, this.cvc);
			},
			updateGTMEvent(event,origin,transaction_id){
				const data = {
					currency: 'USD',
					value: this.getSelectedPlan.price ?? 0,
					items: [
						{
							item_id: this.getSelectedPlan.id ?? 0,
							item_name: this.getSelectedPlan.title ?? 0,
							price: this.getSelectedPlan.price ?? 0,
							origin,
						}
					]
				}
				if(transaction_id) data.transaction_id = transaction_id

				analitics.sendCustomEvent(event,data);

			},
			update() {
				this.complete = this.number && this.expiry && this.cvc;
				if (this.number) {
					if (!this.expiry) {
						this.$refs.cardExpiry.focus();
					} else if (!this.cvc) {
						this.$refs.cardCvc.focus();
					}
				} else if (this.expiry) {
					if (!this.cvc) {
						this.$refs.cardCvc.focus();
					} else if (!this.number) {
						this.$refs.cardNumber.focus();
					}
				}
				// no focus magic for the CVC field as it gets complete with three
				// numbers, but can also have four
			},

			tokenCreated(token) {
				console.log(token);
			},

			changePopup(property, value) {
				this.$store.dispatch("popupsToggle", { property, value });
			},
			closeAllPopups() {
				this.$store.dispatch("popupsToggle", {
					property: "upgradePopup",
					value: false,
				});
				if(this.$route.name === 'my account'){
					this.$store.dispatch("refreshUser");
				}
			},
			popupOpen(popupName) {

				(this.confirmSubscriptionPopup = false),
						(this.congratPopup = false),
						(this[popupName] = true);
			},
		},

		computed: {
//		async paymentToken(){
//
//			console.log("this.$store.state.paymentToken", this.$store.state.paymentToken);
//			alert(thist.gpay_token_generated);
//
//
//			if(this.$store.state.paymentToken && this.$store.state.paymentToken.pwa){
//				if(this.$store.state.USER){
//					this.paymentMethodDetails = this.$store.state.paymentToken;
//					this.createUserAndSubscribe(true);
//				}else{
//					this.paymentMethodDetails = this.$store.state.paymentToken;
//				}
//			}
//			return this.$store.state.paymentToken;
//		},

			isLoggedIn() {
				return this.$store.state.USER;
			},

			openHidePromoInput() {
				return this.promo_code.isOpenInput;
			},

			promoCodeUpdate() {
				return this.promo_code.data;
			},

			getPromoCodeBlockStyle() {
				try {
					const isOpen = this.promo_code.isOpenInput;
					const data = this.promo_code.data;

					let height = isOpen && !data ? "58px" : "0";
					if (data) {
						height = "58px";
					}
					let marginTop = isOpen && !data ? "38px" : "";

					return { marginTop, height, opacity: isOpen && !data ? 1 : 0 };
				} catch (err) {
					console.error(err);
					return {};
				}
			},
			getSelectedPlan(){
				return this.plans.find(item => item.id == this.selectedPlanId) || [];
			}
		},
		watch: {
			paymentToken(){
			},

			openHidePromoInput() {
				this.inputPromoCode();
			},

			promoCodeUpdate(data) {
				if (data) {
					const plan = this.plans.find(
							(pl) => pl.stripe_id == this.promo_code.data.plan_id
					);

					this.promo_code.isFree = this.parsePlanPrice(plan) == "FREE";
				} else {
					this.promo_code.isFree = false;
				}
			},

			number() {
				this.update();
			},
			expiry() {
				this.update();
			},
			cvc() {
				this.update();
			},
		},
	};
</script>

<style scoped>
	.form_loading {
		display: flex;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.lds-ring {
		display: inline-block;
		position: relative;
		width: 80px;
		height: 80px;
	}
	.lds-ring div {
		box-sizing: border-box;
		display: block;
		position: absolute;
		width: 64px;
		height: 64px;
		margin: 8px;
		border: 8px solid #fff;
		border-radius: 50%;
		animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
		border-color: #fff transparent transparent transparent;
	}
	.lds-ring div:nth-child(1) {
		animation-delay: -0.45s;
	}
	.lds-ring div:nth-child(2) {
		animation-delay: -0.3s;
	}
	.lds-ring div:nth-child(3) {
		animation-delay: -0.15s;
	}
	@keyframes lds-ring {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}

	.all-plus-future {
		font-size: 20px;
		font-weight: 300;
		line-height: 1.5;
	}

	.title-success-signin {
		font-weight: inherit !important;
		font-family: inherit;
		font-size: 32px !important;
		font-weight: 400px;
	}

	.box-success-signin {
		font-size: 20px;
		font-weight: 300;
		line-height: 1.5;
	}
	.subs_popup .form-group label {
		font-weight: 400;
	}

	.form-group .label {
		font-weight: 400 !important;
	}

	.popup.save_popup.upgrade-popup .checkbox {
		font-weight: 400 !important;
	}

  .popup.save_popup.upgrade-popup.subs_popup {
		padding: 70px 100px 60px 100px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 6px 99px #00000029;
    border-radius: 8px;
    opacity: 1;
	}

	select:active {
		background: #f5f5f5 0% 0% no-repeat padding-box;
	}

	.promoButton {
    font: normal normal normal 12px/27px Soleil;
		float: right;
		position: absolute;
		bottom: -26px;
		right: 7px;
		text-decoration: underline;
    letter-spacing: -0.24px;
    color: #828C9C;
    opacity: 0.8;
	}

	.plan-type-wrap {
		position: relative;
	}

	.promo_code_block {
		margin-top: 38px;
		z-index: 99;
		transition: 0.3s;
		height: 0;
		overflow: hidden;
		position: relative;
	}

	.promo_code_block > input {
		border: 1px solid rgba(0, 0, 0, 0);
		background: #f5f5f5;
		border-radius: 5px;
		width: 100%;
		padding: 14px;
		display: block;
		border: 1px solid #ececec;
		border-radius: 5px;
		color: #757575;
		font-size: 14px;
		font-family: inherit;
		padding-right: 52px;
	}

	.promo_code_block > input:focus {
		border: 1px solid #ececec;
	}

	/* .checkActivePromocode.active {
        background: #fa6401;
        color: #fff;
        pointer-events: all;
    } */

	.promo_error {
		border: 1px solid #ff5352 !important;
	}

	.promo_error::placeholder {
		color: #ff5352;
	}

	.promo_code_info {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		background: #a4ecdc 0% 0% no-repeat padding-box;
		border-radius: 5px;
		padding: 18.5px;
		margin-bottom: 12px;
		position: absolute;
		width: 100%;
		top: 0px;
		transition: 0.3s;
		opacity: 0;
	}

	.promo_code_info > .left {
		flex: 1;
		text-align: left;
	}

	.promo_code_info > .left > ._code {
		letter-spacing: 0.2px;
		color: #2a2a2a;
		font: normal normal 600 14px/17px Proxima Nova;
		margin-right: 4px;
	}

	.promo_code_info > .right {
		font: normal normal bold 12px/15px Proxima Nova;
		opacity: 0.8;
		color: #2a2a2a;
	}

	.remove_promo_btn:hover {
		color: #d40000;
	}

	.promo_applied {
		position: absolute;
		right: 0;
		top: -6px;
		background: #4adaba;
		padding: 6px;
		border-radius: 0px 5px 3px 3px;
		text-align: left;
		font: normal normal 600 9px/11px Proxima Nova !important;
		letter-spacing: 0px;
		color: #ffffff !important;
		text-transform: uppercase;
		opacity: 1;
	}

	.decoration {
		text-decoration: line-through;
	}

	.promo_button {
		top: 0;
		width: 40px;
		display: block;
		cursor: default;
		height: 38px;
		right: 0;
		position: relative;
		pointer-events: none;
	}

	.promo_button.act ._bg {
		fill: #fa6400;
	}

	.promo_button.act ._arrow {
		stroke: #fff;
	}

	.promo_button.act {
		pointer-events: all;
		cursor: pointer;
	}

	.popup.save_popup.upgrade-popup .checkbox {
		transition: 0.3s;
	}

	.popup.save_popup.upgrade-popup .checkbox.hide_card {
		margin-top: 87px;
	}

	.upgrade-popup {
		transition: 0.2s;
	}

	.card-data.hide_card {
		bottom: 275px;
		pointer-events: none;
		opacity: 0;
	}

	.wrap_promo_plan {
		background: #fff;
		z-index: 999999999;
		position: relative;
		display: block;
	}
	.input-drop-shadow {
		height: 44px;
		margin-top: -5px;
		box-shadow: 0px 1px 2px #00000014;
		-webkit-appearance: none;
	}
	.member_sign_in {
		margin-top: 16px;
		color: #828C9C;
		font-size: 14px;
		line-height: 20px;
		letter-spacing: -0.28px;
		font-weight: 400;
	}
	.member_sign_in a{
		color: #FA6400;
	}
  .form-group.sign-up {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .signup-fields {
    width: calc(50% - 6px);
  }
  .popup.save_popup.upgrade-popup .plan-type span {
    color: #828C9C;
    opacity: 1;
  }
  .form-control {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 2px #00000014;
    border: 1px solid #F2F2F2;
  }
  .popup.save_popup.upgrade-popup .title {
    letter-spacing: -0.72px;
    font-weight: 500;
    font-size: 36px;
  }
  .popup.save_popup.upgrade-popup .plan-type strong {
    font: normal normal normal 16px/18px Soleil;
    letter-spacing: -0.32px;
  }
  .popup.save_popup.upgrade-popup p {
    text-align: center;
    letter-spacing: 0px;
    opacity: 0.8;
  }
  .popup.save_popup.upgrade-popup .icon img {
    width: 41px;
    height: auto;
  }
  .popup.save_popup.upgrade-popup p {
    margin: 0 auto;
  }
  .popup.save_popup.upgrade-popup .btn-wrap {
    margin-top: 20px !important;
  }
  .popup.save_popup.upgrade-popup .agreement_detail p {
    max-width: 400px;
  }
  .popup.save_popup.upgrade-popup.editorpage p {
    font-size: 18px;
    line-height: 24px;
    margin: 0 auto 14px;
    color: #828C9C;
    max-width: 366px;
    letter-spacing: -0.36px;
  }
</style>
